import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import Select from "@mui/material/Select";

import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";

import Tickbox from "components/tickbox";

export default function MultipleSelectCheckmarks({ isNavbarOpen, ...props }) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const classes = useStyles({ isNavbarOpen, theme, isDarkMode });
  const getLabelText = () => {
    if (props.value?.length === 0) {
      return props.label;
    }
    return "";
  };

  const handleChange = (event) => {
    props.onChange(props.type)(event);
  };

  const getCheckBoxColor = () => ({
    color: "#9a0143",
    labelColor: "#5b5d63",
  });

  const getSelectAllOption = () =>
    props.value.length === props.lists.length ? (
      <MenuItem key={"all"} value={"unCheckAll"}>
        <Tickbox
          id={"unCheckAll" + props.type}
          setValue={() => {}}
          isChecked={true}
          name={"Select All"}
          {...getCheckBoxColor()}
        />
      </MenuItem>
    ) : (
      <MenuItem key={"all"} value={"checkAll"}>
        <Tickbox
          id={"all" + props.type}
          setValue={() => {}}
          isChecked={false}
          name={"Select All"}
          {...getCheckBoxColor()}
        />
      </MenuItem>
    );
  return (
    <div>
      <FormControl
        disabled={props.isLoading}
        className={props.cssClass ?? classes.form}
        size="small"
        data-testid="multiselect"
      >
        <InputLabel
          id="demo-multiple-checkbox-label"
          className={classes.label}
          disableAnimation={true}
          shrink={false}
        >
          {getLabelText()}
        </InputLabel>

        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          inputProps={{ "data-testid": props.dataTestid }}
          value={props.value}
          onChange={(evt) => handleChange(evt)}
          renderValue={(selected) => selected.join(", ")}
          sx={{
            backgroundColor: (theme) => theme.palette.bgColor,

            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#E2E8F0",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#E2E8F0",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#E2E8F0",
            },
            ".MuiSvgIcon-root": {
              fill: (theme) => theme.palette.text.primary,
            },

            ".css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
              fontSize: "1.6vw",
            },
            height: "44px",
          }}
        >
          {getSelectAllOption()}

          {props.lists.map((l) => (
            <MenuItem key={l} value={l}>
              <Tickbox
                id={l}
                setValue={() =>
                  handleChange({ target: { value: props.value } })
                }
                isChecked={props.value.includes(l)}
                name={l}
                {...getCheckBoxColor()}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
