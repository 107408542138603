import { Chip, useTheme } from "@mui/material";
import { useStyles } from "./styles";

const Tags = ({
  item = [],
  id = 1,
  name,
  onClick,
  dataTestId = "tag",
  selected = true,
  getStyles,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <Chip
      className={classes.chip}
      key={id}
      size="small"
      data-testid={dataTestId}
      onClick={onClick}
      selected={selected}
      style={getStyles(item)}
      label={name}
    />
  );
};

export default Tags;
