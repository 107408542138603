import { useState } from "react";
import { FaSortDown } from "react-icons/fa6";
import PlotDuration from "../PlotDuration";
import PlotQuantity from "../PlotQuantity";
import Box from "@mui/material/Box";
import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";
import Tickbox from "components/tickbox";
import { ClickAwayListener } from "@mui/material";

export default function Plot({ data }) {
  const [plotType, setPlotType] = useState("duration");
  const [showColumn, setShowColumn] = useState(false);
  const theme = useTheme();
  const classes = useStyles({ theme });
  const toggleShowColumn = () => {
    setTimeout(() => {
      setShowColumn(false);
    }, 100);
  };
  return (
    <Box
      sx={{
        mt: 10,
      }}
    >
      <div className={classes.wrapper}>
        <div
          data-testid="lot-status-tracking-analysis"
          className={classes.title}
          onClick={() => setShowColumn((prev) => !prev)}
        >
          <b>Lot Status Tracking Analysis</b>
          <FaSortDown className={classes.arrowIcon} />
        </div>

        {showColumn && (
          <ClickAwayListener onClickAway={() => toggleShowColumn()}>
            <div className={classes.options} data-testid="show-column-popup">
              <Tickbox
                id={"duration"}
                name={"Show Duration"}
                isChecked={plotType === "duration"}
                dataTestId={"show-duration"}
                setValue={() => {
                  setPlotType("duration");
                  toggleShowColumn();
                }}
              />
              <Tickbox
                id={"quantity"}
                name={"Show Quantity"}
                dataTestId={"show-quantity"}
                isChecked={plotType === "quantity"}
                setValue={() => {
                  toggleShowColumn();
                  setPlotType("quantity");
                }}
              />
            </div>
          </ClickAwayListener>
        )}
      </div>
      <Box
        sx={{
          padding: 2,
          boxShadow: "0px 5px 14px 0px #00000033",
          borderRadius: "10px",
          backgroundColor: theme.palette.bgColor,
        }}
        data-testid="plot-container"
      >
        {plotType === "duration" ? (
          <PlotDuration
            x={data.map((lot) => lot["LotNumber"])}
            tableRowsPerPage={data}
          />
        ) : (
          <PlotQuantity
            x={data.map((lot) => lot["LotNumber"])}
            tableRowsPerPage={data}
          />
        )}
      </Box>
    </Box>
  );
}
