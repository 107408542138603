import { Grid, Typography, Button, useTheme } from "@mui/material";
import { useStyles } from "./style";
import React, { Fragment, useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import dayjs from "dayjs";
import { filteredDateRanges } from "./calendar-types";
import Tags from "components/tags";
import Tickbox from "components/tickbox";
import constants from "../../constants/constants.json";

const MultiCalendar = ({
  setShowCalendar,
  showCheckBoxes = true,
  onDateSelect,
  defaultRange,
  showRangeFilter = true,
  selectedCheckboxes = () => {},
  clearFilters = false,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDateRangeFilter, setSelectedDateRangeFilter] =
    useState(defaultRange);
  const [checkboxList, setCheckboxList] = React.useState({
    dieReceipt: true,
    bump: true,
    probe: true,
    assembly: true,
    test: true,
    ship: true,
  });

  const checkboxes = [
    { id: "dieReceipt", name: "Die Receipt" },
    { id: "bump", name: "Bump" },
    { id: "probe", name: "Probe" },
    { id: "assembly", name: "Assembly" },
    { id: "test", name: "Test" },
    { id: "ship", name: "Ship" },
  ];

  const dateRangeSelector = (id) => {
    const today = dayjs();
    const startDate = dayjs("2019-01-01");
    const endDate = dayjs();
    let range;
    switch (id) {
      case 1:
        range = [today.startOf("day"), today.endOf("day")];
        break;
      case 2:
        range = [
          today.subtract(1, "day").startOf("day"),
          today.subtract(1, "day").endOf("day"),
        ];
        break;
      case 3:
        range = [today.startOf("week"), today.endOf("day")];
        break;
      case 4:
        range = [today.subtract(6, "day").startOf("day"), today.endOf("day")];
        break;
      case 5:
        range = [today.subtract(13, "day").startOf("day"), today.endOf("day")];
        break;
      case 6:
        range = [today.subtract(29, "day").startOf("day"), today.endOf("day")];
        break;
      case 7:
        range = [today.startOf("month"), today.endOf("day")];
        break;
      case 8:
        range = [
          today.subtract(1, "month").startOf("month"),
          today.subtract(1, "month").endOf("month"),
        ];
        break;
      case 9:
        range = [today.startOf("year"), today.endOf("day")];
        break;
      case 10:
        range = [
          today.subtract(1, "year").startOf("year"),
          today.subtract(1, "year").endOf("year"),
        ];
        break;
      case 11:
        range = [startDate, endDate.endOf("day")];
        break;
      default:
        range = [startDate, endDate.endOf("day")];
    }
    const formattedDates = range.map((date) => date.format("YYYY-MM-DD"));
    onDateSelect(formattedDates);
    setSelectedDates(formattedDates);
    setSelectedDateRangeFilter(id);
  };
  useEffect(() => {
    selectedCheckboxes(checkboxList);
  }, [checkboxList, selectedCheckboxes]);

  const getAllTimeDateRange = () => {
    const startDate = dayjs("2019-01-01");
    const endDate = dayjs();
    const allTimeRange = [startDate, endDate.endOf("day")];
    const formattedDates = allTimeRange.map((range) =>
      range.format("YYYY-MM-DD")
    );
    return formattedDates;
  };

  useEffect(() => {
    const formattedDates = getAllTimeDateRange();
    setSelectedDates(formattedDates);
    setSelectedDateRangeFilter(11);
  }, []);

  useEffect(() => {
    if (clearFilters) {
      const formattedDates = getAllTimeDateRange();
      setSelectedDateRangeFilter(11);
      setSelectedDates(formattedDates);
      setCheckboxList({
        dieReceipt: true,
        bump: true,
        probe: true,
        assembly: true,
        test: true,
        ship: true,
      });
    }
  }, [clearFilters]);

  const getRangeFilterStyles = (date) => {
    const isSelected = date.id === selectedDateRangeFilter;
    let backgroundColor;
    let color;
    if (isSelected) {
      backgroundColor = "#9a0143";
      color = "#fff";
    }
    return { background: backgroundColor, color: color };
  };
  const handleDateChangeWithinCalendar = (dates) => {
    const formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));
    if (onDateSelect) {
      onDateSelect(dates);
    }
    onDateSelect(formattedDates);
    setSelectedDates(formattedDates);
  };

  return (
    <Grid
      className={classes.calendarWrapper}
      data-testid="select-date-box-calendar-container"
      onClick={(e) => e.stopPropagation()}
    >
      <Calendar
        data-testid="calendar"
        range
        width={"5%"}
        numberOfMonths={2}
        value={selectedDates}
        onChange={handleDateChangeWithinCalendar}
        className={classes.calendar}
      />

      <Grid className={classes.dateRangeContainer}>
        {selectedDates && (
          <Typography className={classes.dateRangeText}>
            {selectedDates.map((date, index) => (
              <Fragment key={date}>
                {index !== 0 && " - "}
                {date}
              </Fragment>
            ))}
          </Typography>
        )}
      </Grid>
      <Grid className={classes.checkboxFormGroup}>
        {showCheckBoxes &&
          checkboxes.map((checkbox) => {
            return (
              <Fragment key={checkbox.id}>
                <Tickbox
                  id={checkbox.id}
                  name={checkbox.name}
                  isChecked={checkboxList[checkbox.id]}
                  setValue={(e) =>
                    setCheckboxList({
                      ...checkboxList,
                      [checkbox.id]: e.target.checked,
                    })
                  }
                />
              </Fragment>
            );
          })}
      </Grid>

      <Grid className={classes.dateRangeFilterWrapper}>
        {showRangeFilter &&
          filteredDateRanges.map((dateRange) => (
            <Fragment key={dateRange.id}>
              <Tags
                id={dateRange.id}
                getStyles={() => getRangeFilterStyles(dateRange)}
                item={dateRange}
                onClick={() => dateRangeSelector(dateRange.id)}
                selected={dateRange.id === selectedDateRangeFilter}
                name={dateRange.name}
                dataTestId={"tag-" + dateRange.name.split(" ").join("-")}
              />
            </Fragment>
          ))}
        <Button
          data-testid="close-btn"
          className={classes.closeBtn}
          onClick={() => setShowCalendar(false)}
        >
          {constants.close}
        </Button>
      </Grid>
    </Grid>
  );
};

export default MultiCalendar;
