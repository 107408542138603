export const commonMinWidthFunction = (showYield, showDates, showDuration) => {
  // Combine the first three cases
  if (!showYield && (!showDates || !showDuration)) {
    return "100%";
  }

  if (showYield && !showDates && !showDuration) {
    return "100%";
  }

  if (!showYield && showDates && showDuration) {
    return "1935px";
  }

  return "2910px";
};
