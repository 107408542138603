import { AppBar, Typography, Box, Toolbar, Grid } from "@mui/material";
import { useLocation, useMatch, useSearchParams } from "react-router-dom";
import Dropdown from "../../../components/dropdown";
import Search from "../../../components/search";
import CustomBreadcrumbs from "../../breadcrumbs";
import { useAppContext } from "../../../context/appContext";
import { useStyles } from "./styles";
import constants from "../../../constants/constants.json";
import React, { useEffect, useState } from "react";
import CustomTabs from "components/supplyAndDemand/Tabs/customTabs";
import { supplayAndDemandGetProductById } from "pages/supplyDemand/supplyDemand.service";

const Header = ({
  isNavbarOpen,
  handleSearchQueryChange,
  searchQuery,
  children,
  ...props
}) => {
  const {
    filterOption,
    sortOption,
    setSortOption,
    handleFilterChange,
    selectedFilters,
  } = useAppContext();
  const classes = useStyles({ isNavbarOpen });
  const filters = [{ Id: 1, Name: constants.advanceFilters }];
  const sortBy = [
    { Id: 1, Name: constants.sortAsc },
    { Id: 2, Name: constants.sortDesc },
  ];
  const [product, setProduct] = useState();
  const [searchParams] = useSearchParams();

  const match = useMatch("/overview/entry/:pid/:fid?/:tid?/*");
  let { fid, pid, tid } = match?.params || 2;

  if (searchParams.get("template")) {
    tid = searchParams.get("template").split("/")?.pop();
  }

  const getFilterPlaceHolder = () => {
    if (selectedFilters.length) {
      return constants.advanceFilters;
    }
    //NOSONAR  since we dont have filterByFavorites as of now commenting this
    //NOSONAR    if (filterOption?.filterByFavorites) {
    //NOSONAR      return constants.favorites;
    //NOSONAR    }
    return "";
  };

  const location = useLocation();

  const isHomePage =
    location.pathname
      .split("/")
      .filter((x) => x)
      .map((x) => "/" + x).length === 0;

  const getProductById = React.useCallback(async () => {
    const data = await supplayAndDemandGetProductById({
      React,
      productId: pid,
    });

    if (data) {
      setProduct(data.items[0]);
    }
  }, [pid, setProduct]);

  useEffect(() => {
    pid && getProductById();
  }, [getProductById, pid]);

  const isDrilldown = location.pathname.includes("overview");

  return (
    <AppBar className={classes.appBar} data-testid="appBar">
      <Toolbar disableGutters>
        <Box className={classes.navContainer}>{children}</Box>
        <Box className={classes.headerWrapper}>
          <Grid item xs={12} md={3} sm={6} className={classes.title}>
            <Box className={classes.titleWrapper}>
              <Box className={classes.breadcrumbWrapper}>
                <CustomBreadcrumbs product={product} fid={fid} tid={tid} />
              </Box>
              {isHomePage && (
                <Typography
                  className={classes.headerTitle}
                  variant="h4"
                  data-testid="headerTitle"
                >
                  {constants.overviewTitle}
                </Typography>
              )}
            </Box>
            {isHomePage && (
              <div className={classes.inputWrapper}>
                <Dropdown
                  dataTestid="filterBy"
                  Id="select-outlined"
                  value={getFilterPlaceHolder()}
                  lists={filters}
                  setDropdownValue={handleFilterChange}
                  label={constants.filterBy}
                  isNavbarOpen={isNavbarOpen}
                />
                <Dropdown
                  dataTestid="sortBy"
                  Id="select-outlined"
                  value={sortOption}
                  lists={sortBy}
                  setDropdownValue={setSortOption}
                  label={constants.sortBy}
                  isNavbarOpen={isNavbarOpen}
                />
                <Search
                  dataTestid="search"
                  label={constants.searchProductLabel}
                  handleSearchQueryChange={handleSearchQueryChange}
                  searchQuery={searchQuery}
                  isNavbarOpen={isNavbarOpen}
                />
              </div>
            )}
          </Grid>
        </Box>
      </Toolbar>
      {isDrilldown && (
        <CustomTabs isNavbarOpen={isNavbarOpen} product={product} />
      )}
    </AppBar>
  );
};

export default Header;
