import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  tableWrapper: {
    height: "64vh",
    width: "100%",
  },
  table: {
    minWidth: 1000,
    minHeight: 400,
    borderRadius: "10px",
    borderColor: ({ theme }) => theme.palette.themeColor,
    boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.2)",
    backgroundColor: ({ theme }) => theme.palette.bgColor,
  },
});
