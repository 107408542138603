import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    profile: {
        textWrap: "pretty",
        color: ({ theme }) => theme.palette.text.primary,
        backgroundColor: '#23241f',
        minHeight: '55vh',
        alignContent: 'center'
    },
})