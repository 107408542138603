import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  paginationContainer: {
    width: "100%",
    margin: "auto",
    borderRadius: "10px",
  },

  lotsTableContainer: {
    padding: "1rem",
    borderRadius: "10px 10px 0 0",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 14px 0px"
  },

  lotsTableHeadersWrapper: {
    width: "100%",
    height: "61vh",
    margin: "auto",
    overflow: "auto",
    minHeight: '500px'
  },

  // Head css start here

  headersContainer: {
    maxWidth: "100%",
    height: "9%",
    display: "flex",
    position: "sticky",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 3,
  },

  stickyHeader: {
    position: "sticky",
    left: 0,
    zIndex: 2,
  },

  dateHeadTitle: {
    flex: "auto",
    height: "100%",
  },

  dateHeaderWrapper: {
    minWidth: "135px",
    maxWidth: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    cursor: "pointer",
  },

  cursorptr: {
    cursor: 'pointer'
  },

  dateHeader: {
    fontSize: "12px",
    fontWeight: 500,
  },

  durationHeadTitle: {
    minWidth: "50px",
    width: "100%",
    height: "100%",
  },

  durationHeaderWrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
  },

  durationSortWrapper: {
    width: "1px",
    height: "100%",
    margin: "auto",
    backgroundColor: ({ theme }) => theme.palette.text.primary
  },

  durationSortIconsWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#efefef",
    color: "#0a0a0a",
    fontWeight: 500,
    width: "25px",
    height: "26px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  yieldColumnHeader: {
    width: "135px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flex: "auto",
  },

  yieldHeaderWrapper: {
    minWidth: "135px",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "4px",
    fontSize: "12px",
    fontWeight: 500,
  },

  ratioHeader: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flex: "auto",
  },

  yieldHeadColumnWrapper: {
    fontSize: "12px",
    fontWeight: 500,
    minWidth: "135px",
    display: "flex",
    gap: "4px",
    paddingLeft: "20%",
  },

  arrowIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  // Body css start here

  lotsContentWrapper: {
    maxWidth: "100%",
    height: "9%",
    display: "flex",
  },

  columnContent: {
    flex: "auto",
    height: "100%",
  },

  titleContent: {
    minWidth: "135px",
    maxWidth: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },

  dateColumnContent: {
    fontWeight: 500,
    fontSize: "12px",
  },

  contentDuration: {
    minWidth: "50px",
    width: "100%",
    height: "100%",
  },

  titleDiffContent: {
    width: "100%",
    height: "100%",
    position: "relative",
  },

  durationHeaderContent: {
    width: "1px",
    backgroundColor: ({ theme }) => theme.palette.text.primary,
    height: "100%",
    margin: "auto",
  },

  durationContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#565a75",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "10px",
    color: "#fff",
    fontWeight: 500,
  },

  yieldColumnContainer: {
    flex: "auto",
    width: "135px",
    height: "100%",
  },

  yieldContainer: {
    minWidth: "135px",
    height: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "4px",
  },

  yieldWrapper: {
    width: "80%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  yieldContentValue: {
    fontSize: "12px",
    fontWeight: 500,
  },

  dieValue: {
    fontSize: "10px",
    fontWeight: 500,
  },

  yieldProgressContainer: {
    width: "80%",
    margin: "auto",
    height: "8px",
    borderRadius: "10px",
    backgroundColor: "#d1d1d191",
  },

  yieldProgressBar: {
    height: "100%",
    borderRadius: "10px",
  },

  yieldValueContent: {
    height: "100%",
    flex: "auto",
    justifyContent: "center",
    alignItems: "center",
  },

  dieValueText: {
    minWidth: "135px",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 500,
    height: "100%",
    display: "grid",
    placeItems: "center",
  },

  customPaginationContainer: {
    width: "100%",
    margin: "auto",
    marginTop: "0rem",
    height: "60px",
    padding: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "0 0 10px 10px",
  },

  paginationWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "60px",
    marginLeft: "40% !important",
  },

  themeBackgroundColor: {
    backgroundColor: ({ isDarkMode }) => isDarkMode ? "#1D1F2E" : "#FFFFFF"
  },

  themeColor: {
    color: ({ isDarkMode }) => isDarkMode ? "#FFFFFF" : "#5b5d6e"
  }
});
