// import Cookies from "js-cookie";
export const authCookie = (opt) => {
  const hostname = window.location.hostname;
  let {
    name,
    value,
    domain = hostname,
    path = "/",
    expires = 7,
    secure = false
  } = opt;
  // Cookies.set('authorizationcookie', token, { secure: true, domain: 'api.glasswall.advantest.com', path: '/' });
  // document.cookie = `authorizationcookie=${token};domain=.glasswall.advantest.com;path=/`;
  if (!name || !value) return console.log("Set cookie:", name, value);
  if (!domain.endsWith(hostname)) domain = hostname;
  let cks = `${name}=${value};domain=${domain};expires=${expires};path=${path};secure=${secure};SameSite=lax`;
  //    let cks = `${name}=${value};domain=${domain};path=${path}`;
  document.cookie = cks;
};