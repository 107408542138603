import { useState } from "react";
import MultipleSelectCheckmarks from "components/dropdown/multiSelectDropdown";
import { RiArrowRightCircleFill, RiDownload2Fill } from "react-icons/ri";
import { MdFilterAltOff } from "react-icons/md";
import Search from "components/search";
import MultiCalendar from "components/multiCalendar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import clsx from "clsx";
import { useTheme } from "@emotion/react";
import { Grid, Box, Button, IconButton, Tooltip } from "@mui/material";
import { useStyles } from "./styles";
import constants from "../../constants/constants.json";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Filters = ({ ...props }) => {
  const theme = useTheme();

  const classes = useStyles({
    theme,
    isNavbarOpen: props.isNavbarOpen,
  });

  const [showCalendar, setShowCalendar] = useState(false);

  const onDateSelect = ([start, end]) => {
    props.onDateChange({ start, end });
  };

  return (
    <Grid
      data-testid="filters"
      sx={{
        mb: 2,
        ml: 1,
        display: "flex",
        justifyContent: "space-between",
        minWidth: 1450,
      }}
      container
      columns={7}
    >
      <Grid item>
        <Search
          label="Serial Number"
          cssClass={classes.search}
          handleChange={props.onSearchChange}
          value={props.filter?.search}
        />
      </Grid>

      <Grid item>
        <MultipleSelectCheckmarks
          isLoading={props.isLoading}
          dataTestid="partNumbers"
          cssClass={classes.commonDropDownStyle}
          label="Part Number"
          value={props.filter.partNumbers}
          Id="select"
          type="partNumbers"
          onChange={props.onDropDownChange}
          lists={props.partNumbers}
          MenuProps={MenuProps}
          multiple={true}
        />
      </Grid>
      <Grid item>
        <MultipleSelectCheckmarks
          dataTestid="productTypes"
          isLoading={props.isLoading}
          cssClass={classes.commonDropDownStyle}
          label="Product Type"
          value={props.filter.productTypes}
          Id="select"
          type="productTypes"
          onChange={props.onDropDownChange}
          lists={props.productTypes}
          MenuProps={MenuProps}
          multiple={true}
        />
      </Grid>
      <Grid item>
        <MultipleSelectCheckmarks
          isLoading={props.isLoading}
          dataTestid="projectNames"
          cssClass={classes.commonDropDownStyle}
          label="Project Name"
          value={props.filter.projectNames}
          Id="select"
          type="projectNames"
          onChange={props.onDropDownChange}
          lists={props.projectNames}
          MenuProps={MenuProps}
          multiple={true}
        />
      </Grid>
      <Grid item>
        <Grid
          className={classes.calendarInput}
          data-testid="select-date-box"
          onClick={() => setShowCalendar((prev) => !prev)}
        >
          <Button className={classes.btn} data-testid="selected-date">
            {`${props.filter.dateRange.start} - ${props.filter.dateRange.end}`}

            <ArrowDropDownIcon className={classes.arrowIcon} />
          </Button>
        </Grid>
      </Grid>

      <Grid item>
        <MultipleSelectCheckmarks
          dataTestid="compliantToShipment"
          isLoading={props.isLoading}
          label="Compliant To Shipment"
          value={props.filter.compliantToShipment}
          Id="select"
          type="compliantToShipment"
          onChange={props.onDropDownChange}
          lists={props.compliantToShipment}
          MenuProps={MenuProps}
          multiple={true}
          cssClass={classes.compliantToShipment}
        />
      </Grid>
      <Grid
        item
        sx={{
          mr: 2,
        }}
      >
        <div className={classes.actionBtns}>
          <Tooltip title={constants.clearFilters}>
            <IconButton
              disabled={props.isLoading}
              data-testid="clearFilter"
              onClick={props.clearFilter}
              className={classes.actionBtn}
            >
              <MdFilterAltOff />
            </IconButton>
          </Tooltip>
          <Tooltip title={constants.downloadAll}>
            <IconButton
              disabled={props.isLoading}
              data-testid="download"
              onClick={props.downloadCSV}
              className={classes.actionBtn}
            >
              <RiDownload2Fill />
            </IconButton>
          </Tooltip>

          <Tooltip title={constants.queryDatabase}>
            <IconButton
              disabled={props.isLoading}
              data-testid="queryDatabase"
              onClick={props.query}
              className={classes.actionBtn}
            >
              <RiArrowRightCircleFill />
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
      <Box
        className={clsx(
          classes.calendarAnchor,
          classes.form,
          showCalendar ? classes.active : classes.inactive
        )}
      >
        <MultiCalendar
          open={showCalendar}
          defaultRange={14}
          setShowCalendar={setShowCalendar}
          showCheckBoxes={false}
          onDateSelect={onDateSelect}
          showRangeFilter={true}
        />
      </Box>
    </Grid>
  );
};

export default Filters;
