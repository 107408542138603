import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  useTheme,
} from "@mui/material";
import { useStyles } from "./styles";

const Tickbox = ({
  id,
  name,
  isChecked,
  setValue,
  dataTestId = "checkboxForm",
  color,
  labelColor,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme, color, labelColor });
  return (
    <FormGroup key={id} data-testid={dataTestId}>
      <FormControlLabel
        control={
          <Checkbox
            data-testid={"checkbox"}
            checked={isChecked}
            onChange={(e) => setValue(e)}
            className={classes.checkbox}
            name={name}
          />
        }
        label={
          <Typography className={classes.checkboxLabel}>{name}</Typography>
        }
      />
    </FormGroup>
  );
};

export default Tickbox;
