export const headers = [
    {
        key: "DieReceipt",
        title: "DieReceipt",
        type: 'dates',
    },

    {
        key: "ReceiptBumpDuration",
        type: 'duration',
    },

    {
        key: "BumpIn",
        title: "BumpIn",
        type: 'dates'

    },

    {
        key: "BumpDuration",
        type: 'duration',
    },

    {
        key: "BumpOut",
        title: "BumpOut",
        type: 'dates'
    },

    {
        key: "BumpProbeDuration",
        title: "BumpProbeDuration",
        type: 'duration'
    },

    {
        key: "ProbeIn",
        title: "ProbeIn",
        type: 'dates'
    },

    {
        key: "ProbeDuration",
        type: 'duration'

    },
    {
        key: "ProbeOut",
        title: "ProbeOut",
        type: 'dates',
    },
    {
        key: "ProbeAssemblyDuration",
        type: 'duration'
    },
    {
        key: "AssemblyIn",
        title: "AssemblyIn",
        type: 'dates'
    },
    {
        key: "AssemblyDuration",
        type: 'duration'
    },
    {
        key: "AssemblyOut",
        title: "AssemblyOut",
        type: 'dates'
    },
    {
        key: "AssemblyTestDuration",
        type: 'duration'
    },
    {
        key: "TestIn",
        title: "TestIn",
        type: 'dates'
    },
    {
        key: "TestDuration",
        type: 'duration'
    },
    {
        key: "TestOut",
        title: "TestOut",
        type: 'dates'
    },
    {
        key: "TestShipDuration",
        type: 'duration'
    },
    {
        key: "ShipOut",
        title: "ShipOut",
        type: 'dates'
    },
];