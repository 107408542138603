import { Box, Typography, useTheme } from "@mui/material";

const YieldColumn = ({ dievalue, color, classes }) => {
  return (
    <Typography sx={{ color: color }} className={classes.dieValueText}>
      {dievalue ? dievalue : "-"}
    </Typography>
  );
};

const TakaColumns = ({ showHideList, classes, data }) => {
  const theme = useTheme();

  const { mode } = theme.palette;
  const isDarkTheme = mode === "dark";

  const { mcm_taka_d_bg_color } = theme.lotstatusColorCodes;

  function getColors() {
    return isDarkTheme ? "#FFFFFF" : "#5b5d6e";
  }

  const columnConfigs3 = [
    {
      name: "MCMTakaD",
      className: classes.yieldValueContent,
      background: isDarkTheme ? "rgb(29, 31, 46)" : mcm_taka_d_bg_color,
      color: "red",
      show: showHideList.yield,
      render: (data, column) => (
        <YieldColumn
          dievalue={data?.TestOutDieM}
          color={column.color}
          classes={classes}
        />
      ),
    },

    {
      name: "MCMTaka",
      className: classes.yieldValueContent,
      background: isDarkTheme ? "rgb(29, 31, 46)" : "#E0F8DE",
      color: isDarkTheme ? "#1BC39B" : "darkgreen",
      show: showHideList.yield,
      render: (data, column) => (
        <YieldColumn
          dievalue={data?.TestOutDieN}
          color={column.color}
          classes={classes}
        />
      ),
    },

    {
      name: "TakaDRatio",
      className: classes.yieldValueContent,
      color: "#1D1F2E",
      show: showHideList.yield,
      render: (data, column) => (
        <>
          {data?.TakaDRatio ? (
            <Typography
              sx={{
                color: isDarkTheme
                  ? ColorGenerator(data?.TakaDRatio)
                  : column.color,
              }}
              className={classes.dieValueText}
            >
              {data.TakaDRatio}
            </Typography>
          ) : (
            <Typography
              sx={{ color: theme.palette.text.primary }}
              className={classes.dieValueText}
            >
              -
            </Typography>
          )}
        </>
      ),
    },
  ];

  // Color Code generator Code for the last Column

  function ColorGenerator(percentage) {
    let r,
      g,
      b = 0;

    if (isNaN(percentage)) {
      g = 255;
      r = 0;
    } else if (percentage < 50) {
      g = 255;
      r = Math.round(5.1 * percentage);
    } else {
      r = 255;
      g = Math.round(510 - 5.1 * percentage);
    }

    let h = r * 0x10000 + g * 0x100 + b * 0x1;
    return "#" + ("000000" + h.toString(16)).slice(-6);
  }

  return (
    <>
      {columnConfigs3.map((column, index) => {
        if (!column.show) return null;

        let background;
        if (columnConfigs3.length - 1 === index) {
          background = isDarkTheme
            ? "#1D1F2E"
            : ColorGenerator(data?.TakaDRatio);
        } else {
          background = column.background;
        }

        return (
          <Box
            className={column.className}
            data-testid={column.name}
            sx={{
              background: background,
              borderRight:
                index === columnConfigs3.length - 1
                  ? "none"
                  : `1px solid ${getColors()}`,
            }}
            key={column.name}
          >
            {column.render(data, column)}
          </Box>
        );
      })}
    </>
  );
};

export default TakaColumns;
