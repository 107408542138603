export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          bgColor: "#FFFFFF",
          themeColor: "#FFFFFF",
          fontFamily: "inherit",
          text: {
            primary: "#5b5d6e",
            secondary: "#000",
            disabled: "gray",
          },
          icon: {
            primary: "#B80351",
          },
          // divider: {
          //   primary: "#E6E7F0",
          //   color: "#E6E7F0",
          // },
          footer: {
            color: "#E6E7F0",
          },
          button: {
            primary: "#F4F4F8",
          },
        }
      : {
          bgColor: "#1D1F2E",
          themeColor: "#11131A",
          fontFamily: "inherit",
          text: {
            primary: "#FFFFFF",
            secondary: "#fff",
            disabled: "#FFFFFF",
          },
          icon: {
            primary: "#FFFFFF",
          },
          // divider: {
          //   primary: "#FFFFFF",
          // },
          footer: {
            color: "#1D1F2E",
          },
          button: {
            primary: "#5B5D6E",
          },
        }),
  },
  typography: {
    subtitle2: {
      fontSize: "12px",
      fontFamily: "inherit",
      fontWeight: 700,
      color: "#5B5D6E",
    },
    subtitle1: {
      fontSize: "16px",
      fontFamily: "inherit",
      fontWeight: 600,
      color: "#5B5D6E",
      lineHeight: "200%",
    },
    h4: {
      fontWeight: 700,
      color: "#FCFCFE",
      fontFamily: "inherit",
      fontSize: "35px",
      lineHeight: "140%",
      fontStyle: "normal",
    },
    body1: {
      color: "#1D1F2E",
      textAlign: "center",
      fontFamily: "inherit",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
    },
  },

  lotstatusColorCodes: {
    bg_color_1: "#0d1d7e1a",
    bg_color_2: "#ffeae7",
    bg_color_3: "#fef1ec",
    bg_color_4: "#fffeea",
    bg_color_5: "#e9f9f5",
    bg_color_6: "#e8fdfe",
    bg_color_7: "#565a75",
    bg_color_8: "rgba(99, 99, 99, 0.3)",

    text_color_1: "#9584ff",
    text_color_2: "#c7000c",
    text_color_3: "#dd6b20",
    text_color_4: "#e3a915",
    text_color_5: "#1bc39b",
    text_color_6: "#008eae",

    bg_primary_color: "#565a75",

    progress_bg_color_1: "orangered",
    progress_bg_color_2: "orange",
    progress_bg_color_3: "#f6c324",
    progress_bg_color_4: "#097969",

    border_color: "#a8abc2",

    mcm_taka_d_bg_color: "#fb2b0f1a",
    mcm_taka_bg_color: "rgba(0, 128, 0, 0.372)",
    mcm_taka_bg_color_2: "rgba(0, 128, 0, 0.783)",

    mcm_taka_ratio_bg_color_1: "rgba(0, 128, 0, 0.783)",
    mcm_taka_ratio_bg_color_2: "#ffd700",
    mcm_taka_ratio_bg_color_3: "#febe10",
  },
});
