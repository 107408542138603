import { Box, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import RenderSortIcons from "../utils/sortIcons";

const YieldHeadColumn = ({ sortBy, sortOrder, column, classes }) => {
  return (
    <Box className={classes.yieldHeadColumnWrapper}>
      <Typography
        className={clsx(classes.cursorptr, classes.themeColor)}
        variant="subtitle2"
        fontWeight={500}
      >
        {column.title}
      </Typography>
      {sortBy === column.key ? RenderSortIcons(sortOrder, classes) : null}
    </Box>
  );
};

const TakaHeaders = ({
  showHideList,
  classes,
  toggleSortOrder,
  sortOrder,
  sortBy,
}) => {
  const isDarkMode = useTheme().palette.mode === "dark";
  const takaHeaders = [
    {
      key: "TestOutDieM",
      title: "MCM Taka D",
      className: classes.ratioHeader,
      show: showHideList.yield,
      render: (column) => (
        <YieldHeadColumn
          sortBy={sortBy}
          sortOrder={sortOrder}
          column={column}
          classes={classes}
        />
      ),
    },

    {
      key: "TestOutDieN",
      title: "MCM Taka",
      className: classes.ratioHeader,
      show: showHideList.yield,
      render: (column) => (
        <YieldHeadColumn
          sortBy={sortBy}
          sortOrder={sortOrder}
          column={column}
          classes={classes}
        />
      ),
    },

    {
      key: "TakaDRatio",
      title: "TakaD-Ratio",
      className: classes.ratioHeader,
      show: showHideList.yield,
      render: (column) => (
        <YieldHeadColumn
          sortBy={sortBy}
          sortOrder={sortOrder}
          column={column}
          classes={classes}
        />
      ),
    },
  ];
  return (
    <>
      {takaHeaders.map((column, i) => {
        if (!column.show) return null;

        // Inline calculation for borderRight style
        let borderRightStyle = "1px solid #5b5d6e"; // Default to light mode color

        if (i === takaHeaders.length - 1) {
          borderRightStyle = "none";
        } else if (isDarkMode) {
          borderRightStyle = "1px solid #FFFFFF";
        }

        return (
          <Box
            data-testid={column.key}
            className={column.className}
            onClick={() => toggleSortOrder(column.key)}
            key={column.key}
            sx={{ borderRight: borderRightStyle }}
          >
            {column.render(column)}
          </Box>
        );
      })}
    </>
  );
};

export default TakaHeaders;
