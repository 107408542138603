import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  checkbox: ({ color }) => ({
    ...(color ? { color } : {}),
    transform: "scale(0.8)",
    display: "flex",
    alignItems: "center",
    gridGap: "10px",
    gap: "10px",
    fontSize: "14px",
    padding: "6px",
    "&.Mui-checked": {
      color: "#9a0143",
    },
    "&.MuiCheckbox-root": {
      zIndex: "1",
    },
    "&.MuiCheckbox-root.Mui-checked::before": {
      content: "''",
      width: "15px",
      height: "15px",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      background: "#fff",
      zIndex: "-1",
    },
  }),
  checkboxLabel: {
    color: ({ theme, labelColor }) => labelColor ?? theme.palette.text.primary,
  },
});
