import { Box, Skeleton } from "@mui/material";
import { useState } from "react";
import { imageStyle } from "./style";

const TakaImage = ({ src, alt }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const classes = imageStyle({ isImageLoaded });
  return (
    <>
      <Box className={classes.imageContainer}>
        {!isImageLoaded && (
          <Skeleton variant="rectangular" className={classes.imageSkeleton}>
            <div
              data-testid="img-skeleton"
              className={classes.image}
              style={{
                height: "100px",
              }}
            />
          </Skeleton>
        )}
      </Box>
      <Box className={classes.imageContainer}>
        <img
          className={classes.image}
          onLoad={() => setIsImageLoaded(true)}
          src={src}
          alt={alt}
        />
      </Box>
    </>
  );
};

export default TakaImage;
