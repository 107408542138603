import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  webplayerBtn: {
    backgroundColor: ({ theme }) => theme.palette.button.primary,
    fontSize: "11px",
    color: ({ theme }) => theme.palette.text.primary,
    border: "2px solid #A8ABC2",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  webplayerWrapper: {
    width: "100%",
    height: "auto",
    backgroundColor: "#fff",
    marginTop: "2rem",
    marginBottom: "5rem",
    boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
  },
  iframeWrapper: {
    backgroundColor: "#fff",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  backBtn: {
    marginRight: "2%",
    backgroundColor: "#8588A1",
    fontWeight: 700,
    fontSize: "12px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#8588A1",
    },
  },
  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  backIcon: {
    marginBottom: "1px",
  },
  iframe: {
    width: "100%",
    padding: ({ isWebPlayerExpanded }) =>
      isWebPlayerExpanded ? " 0px" : "10px",
    height: "100vh",
  },
  expandIcon: {
    backgroundColor: "#8588A1",
    "&:hover": {
      backgroundColor: "#8588A1",
    },
    color: "#fff",
    width: "30px",
    height: "35px",
  },
  parametricContainer: {
    display: ({ openPlayer }) => (openPlayer ? "none" : "flex"),
  },
  tableWrapper: {
    height: "60vh",
    width: "100%",
    marginBottom: "4rem",
    marginTop: "2rem",
    "@media (max-height: 700px)": {
      height: "100%",
      marginTop: "0rem",
    },
  },

  tidNameWrapper: {
    width: "80%",
    textAlign: "left",
  },
  tidName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  webplayerHeaderGrid: {
    paddingRight: "1%",
    paddingLeft: "2.5%",
    paddingTop: "2%",
  },
  detailsHeader: {
    marginLeft: "0.8vw",
  },
});
