import { Box, useTheme } from "@mui/material";
import clsx from "clsx";

const YieldColumn = ({ classes, color, yieldValue, DieValue }) => {
  return yieldValue && DieValue ? (
    <Box className={classes.yieldContainer}>
      <Box className={classes.yieldWrapper}>
        <Box
          component={"span"}
          sx={{ color: color }}
          className={classes.yieldContentValue}
        >
          {yieldValue}%
        </Box>{" "}
        <Box
          component={"span"}
          className={clsx(classes.dieValue, classes.themeColor)}
        >
          {DieValue}
        </Box>
      </Box>
      <Box className={classes.yieldProgressContainer}>
        <Box
          sx={{
            width: yieldValue > 100 ? "100%" : `${yieldValue}%`,
            background: color,
          }}
          className={classes.yieldProgressBar}
        ></Box>
      </Box>
    </Box>
  ) : (
    <Box sx={{ textAlign: "center" }} className={classes.yieldContainer}>
      <Box
        component={"span"}
        sx={{ color }}
        justifyContent={"center !important"}
        className={classes.yieldWrapper}
      >
        -
      </Box>
    </Box>
  );
};

const YieldColumnsContent = ({ showHideList, classes, data }) => {
  const theme = useTheme();

  const { mode } = theme.palette;
  const isDarkTheme = mode === "dark";

  const {
    bg_color_2,
    bg_color_3,
    bg_color_4,
    bg_color_5,

    progress_bg_color_1,
    progress_bg_color_2,
    progress_bg_color_3,
    progress_bg_color_4,
  } = theme.lotstatusColorCodes;

  function getColors() {
    return isDarkTheme ? "#FFFFFF" : "#5b5d6e";
  }

  const columnConfigs2 = [
    {
      name: "BumpInYield",
      className: classes.yieldColumnContainer,
      background: bg_color_2,
      color: progress_bg_color_1,
      show: showHideList.yield,
      render: (data, column) => (
        <YieldColumn
          classes={classes}
          yieldValue={data?.BumpYield}
          DieValue={data?.BumpOutDie}
          color={column.color}
        />
      ),
    },

    {
      name: "ProbeInYield",
      className: classes.yieldColumnContainer,
      background: bg_color_3,
      color: progress_bg_color_2,
      show: showHideList.yield,
      render: (data, column) => (
        <>
          <YieldColumn
            classes={classes}
            yieldValue={data?.ProbeYield}
            DieValue={data?.ProbeOutDie}
            color={column.color}
          />
        </>
      ),
    },

    {
      name: "AssemblyInYield",
      className: classes.yieldColumnContainer,
      background: bg_color_4,
      color: progress_bg_color_3,
      show: showHideList.yield,
      render: (data, column) => (
        <>
          <YieldColumn
            classes={classes}
            yieldValue={data?.AssemblyYield}
            DieValue={data?.AssemblyOutDie}
            color={column.color}
          />
        </>
      ),
    },

    {
      name: "TestInYield",
      className: classes.yieldColumnContainer,
      background: bg_color_5,
      color: progress_bg_color_4,
      show: showHideList.yield,
      render: (data, column) => (
        <>
          <YieldColumn
            classes={classes}
            yieldValue={data?.TestYield}
            DieValue={data?.TestOutDie}
            color={column.color}
          />
        </>
      ),
    },
  ];
  return (
    <>
      {columnConfigs2.map(
        (column, index) =>
          column.show && (
            <Box
              className={column.className}
              data-testid={column.name}
              sx={{
                background: isDarkTheme ? "#1D1F2E" : `${column.background}`,
                borderLeft: index === 0 && `3px solid ${getColors()}`,
                borderRight: `1px solid ${getColors()}`,
              }}
              key={column.name}
            >
              {column.render(data, column)}
            </Box>
          )
      )}
    </>
  );
};

export default YieldColumnsContent;
