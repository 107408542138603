import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import BasicTable from "components/table";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingSpinner from "components/loadingSpinner";
import Emptystate from "components/emptyState";
import constants from "../../../constants/constants.json";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import logo from "../../../assets/images/Advantest logo.png";
import PropTypes from "prop-types";

const colNames = constants.helvetiaColumn;
const HelvetiaCompliancyCheckDetails = ({
  setOpenDetails,
  isNavbarOpen,
  disableVirtualization = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pid, fid, tid } = useParams();
  const classes = useStyles({ theme, isNavbarOpen });

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState({});
  const [pdfTableModules, setPdfTableModules] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const onPaginationModelChange = ({ page, pageSize }) => {
    setPaginationModel({
      page,
      pageSize,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    React.$fetch(`${React.$apis.systemEdcParser}/edc-file?filename=${tid}`)
      .then((res) => {
        setApiData(res.data);

        const modulesArray = res.data?.Components?.flatMap(
          (item) => item.Modules
        );
        const tableModules = [];
        modulesArray.map((module) => {
          return tableModules.push(module);
        });
        setPdfTableModules(tableModules);
        const rows = modulesArray.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setData(rows);
        setIsLoading(false);
      })
      .catch(() => {
        console.log("Error in Fetching Data");
        setIsLoading(false);
      });
  }, [tid]);

  useEffect(() => {
    if (!tid) {
      navigate(`/overview/entry/${pid}/${fid}`);
      setOpenDetails(false);
    }
  }, [fid, navigate, setOpenDetails, pid, tid]);

  const getIcons = useCallback(
    (status) => {
      switch (status.toLowerCase()) {
        case "yes":
          return <CheckBoxIcon className={classes.statusYes} />;
        case "no":
          return <DisabledByDefaultIcon className={classes.statusNo} />;
        default:
          return <HelpCenterIcon className={classes.unknownStatus} />;
      }
    },
    [classes]
  );

  const columns = colNames.map((col) => ({
    field: col.id,
    headerName: col.label,
    minWidth: col.minWidth,
    maxWidth: col.maxWidth,
    description: col.description,
    flex: col.flex,
    headerAlign: col.headerAlign,

    //if the column is CompliancyStatus, render the cell with icons
    renderCell: (params) => {
      if (params.field === "CompliancyStatus") {
        return (
          <div className={classes.cell}>
            {getIcons(params.value)}
            <Typography className={classes.statusText}>
              {params.value}
            </Typography>
          </div>
        );
      }
      return (
        <div className={classes.rowCell} title={params.value}>
          {params.value}
        </div>
      );
    },
  }));

  const createFooter = (doc) => {
    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    doc.setFontSize(8);
    for (let j = 1; j < pages + 1; j++) {
      const horizontalPos = pageWidth / 2;
      const verticalPos = pageHeight - 5;
      doc.setPage(j);
      doc.text(`${j}`, horizontalPos, verticalPos, { align: "center" });
    }
  };
  const pdfHeaders = colNames;

  const downloadPDF = () => {
    const doc = new jsPDF("landscape");
    const img = logo;
    doc.addImage(img, "png", 10, 10, 35, 5);
    doc.setFontSize(20);
    doc.text(20, 30, "Advantest - Report for Helvetia Compliancy Check");
    doc.setFontSize(11);
    doc.text(
      20,
      45,
      "Details Corresponding to Edc File: " + apiData?.Metadata.Filename
    );
    doc.text(220, 45, "Compliant To Shipment: " + apiData?.CompliancyStatus);
    doc.text(
      20,
      55,
      "Glasswall Revision Rule Set: " + apiData?.Metadata.RulesRev
    );
    doc.text(120, 55, "Computation Date: " + apiData?.Metadata.DatetimeParsed);
    doc.text(20, 65, "Hostname: " + apiData?.Host.Hostname);
    doc.text(120, 65, "Workstation Model: " + apiData?.Host.WorkstationModel);
    doc.text(220, 65, "Workstation SN: " + apiData?.Host.WorkstationSn);
    doc.text(20, 75, "Workstation Type: " + apiData?.Host.WorkstationVmType);
    doc.text(120, 75, "V93000SwVersion: " + apiData?.Host.V93000SwVersion);
    doc.text(
      220,
      75,
      "V93000AddressWidth: " + apiData?.Host.V93000AddressWidth
    );
    doc.text(240, 85, "Workstation BIOS: " + apiData?.Host.WorkstationBios);
    doc.text(20, 85, "Workstation OS: " + apiData?.Host.WorkstationOs);

    const header = ["SNo."];
    pdfHeaders.map((cell) => header.push(cell.label));
    const rows = [];
    pdfTableModules.forEach((row, index) => {
      return rows.push([
        index + 1,
        row.Modulename,
        row.LogicalBoardNrInTester,
        row.AdvantestPartnumber,
        row.PartRevision,
        row.SubcontractorInformation,
        row.SerialNumber,
        row.ProductionDate,
        row.HLAPartNumber,
        row.HLARevision,
        row.HLAManufacturer,
        row.HLAProductionDate,
        row.HLASerialNumber,
        row.FPGARevision,
        row.CompliancyStatus,
      ]);
    });
    autoTable(doc, {
      head: [header],
      body: rows,
      startY: 90,
      styles: { halign: "center", fontSize: 10 },
    });

    createFooter(doc);

    const date = new Date(),
      formattedDate =
        [
          date.getFullYear(),
          (date.getMonth() + 1).toString().padStart(2, "0"),
          date.getDate().toString().padStart(2, "0"),
        ].join("-") +
        "T" +
        [date.toTimeString().slice(0, 8).replaceAll(":", "")].join("");

    const edcFileName =
      pdfTableModules.find((item) => item?.Modulename === "SYSN")
        ?.SerialNumber || apiData?.Metadata.Filename.split(" ")[0];
    doc.save(`HelvetiaCompliancyData - ${edcFileName} - ${formattedDate}.pdf`);
  };

  if (isLoading) {
    return (
      <Grid className={classes.loadingWrapper}>
        <LoadingSpinner loadingMessage={constants.loading} />
      </Grid>
    );
  }

  if (!apiData?.Host?.Hostname && !apiData.Host?.WorkstationSn) {
    return (
      <Box className={classes.invalidFileWrapper}>
        <Button
          className={classes.back}
          data-testid="back-btn"
          onClick={() => {
            navigate(`/overview/entry/${pid}/${fid}`);
            setOpenDetails(false);
          }}
        >
          Back
        </Button>
        <Emptystate
          data-testid="emptyStateTitle"
          title={constants.invalidFile}
          message={constants.invalidFileHelvetia}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Box data-testid={"details-section"} className={classes.titleWrapper}>
        <Grid className={classes.btnGroup}>
          <Button
            data-testid="generatePDF"
            className={classes.downloadBtn}
            onClick={() => downloadPDF()}
          >
            <DownloadIcon fontSize="small" /> Download
          </Button>

          <Button
            className={classes.backBtn}
            data-testid="back"
            onClick={() => {
              navigate(`/overview/entry/${pid}/${fid}`);
              setOpenDetails(false);
            }}
          >
            Back
          </Button>
        </Grid>
      </Box>
      <Box className={classes.pageWrapper}>
        <Box className={classes.detailsHeader}>
          <p className={classes.detailsTitle}>
            Details about System with EDC file: {apiData?.Metadata?.Filename}
          </p>
          <div className={classes.statusWrapper}>
            <Typography className={classes.shipmentText}>
              Compliant to Shipment:
            </Typography>
            <div className={classes.compliantStatus}>
              {getIcons(apiData?.CompliancyStatus)}
              <Typography className={classes.statusValue}>
                {apiData?.CompliancyStatus}
              </Typography>
            </div>
          </div>
          <div className={classes.detailsData}>
            <Typography className={classes.rows}>
              Glasswall Rev. Rule Set. {apiData?.Metadata?.RulesRev} &nbsp; |
              &nbsp; Computation Date & Time:{" "}
              {apiData?.Metadata?.DatetimeParsed}
            </Typography>
            <Typography className={classes.rows}>
              Hostname: {apiData?.Host?.Hostname} &nbsp; | &nbsp; Workstation
              Model: {apiData?.Host?.WorkstationModel} &nbsp; | &nbsp;
              Workstation SN:
              {apiData?.Host?.WorkstationSn} &nbsp; | &nbsp; Workstation BIOS:{" "}
              {apiData?.Host?.WorkstationBios} &nbsp; | &nbsp; Workstation
              VM-TYPE: {apiData?.Host?.WorkstationVmType}
            </Typography>
            <Typography className={classes.rows}>
              Workstation Model: {apiData?.Host?.WorkstationModel} &nbsp; |
              &nbsp; Workstation SN: {apiData?.Host?.WorkstationSn} &nbsp; |
              &nbsp; Workstation BIOS: {apiData?.Host?.WorkstationBios} &nbsp; |
              &nbsp; Workstation VM-TYPE: {apiData?.Host?.WorkstationVmType}
            </Typography>
          </div>
        </Box>
        <Grid className={classes.tableWrapperGrid}>
          <div className={classes.tableContainer}>
            <BasicTable
              disableVirtualization={disableVirtualization}
              rows={data}
              columns={columns}
              disableColumnMenu={true}
              style={classes.tableStyle}
              paddingX={0}
              showScroller={"scroll"}
              isLoading={isLoading}
              paginationModel={paginationModel}
              onPaginationModelChange={onPaginationModelChange}
              sxProps={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  justifyContent: "center",
                },
              }}
            />
          </div>
        </Grid>
      </Box>
    </Box>
  );
};

HelvetiaCompliancyCheckDetails.propTypes = {
  setOpenDetails: PropTypes.func,
  isNavbarOpen: PropTypes.bool,
  disableVirtualization: PropTypes.bool,
};

export default HelvetiaCompliancyCheckDetails;
