import { localSet, localRemove, cookiesSet } from "./tools";
import { cookieDomain } from "config/settings";
import { authCookie } from "./auths";
import Cookies from "js-cookie";

export const saveAuth = (user, access_token, id_token) => {
  return new Promise((resolve, reject) => {
    if (!user || !access_token || !id_token) return reject(false);
    localSet("user", user);

    cookiesSet("jwtToken", access_token);
    authCookie({
      name: "authorizationcookie",
      value: `Bearer ${access_token}`,
      domain: cookieDomain,
      secure: true
    });
    return resolve(true);
  });
};

export const removeAuth = () => {
  localRemove("user");
  localRemove("jwtToken");
  Cookies.remove("authorizationcookie", {
    path: "/",
    domain: window.location.hostname,
  });
  return Promise.resolve(true);
};
