export const filteredDateRanges = [
    {
        id: 1,
        name: "Today",
    },
    {
        id: 2,
        name: "Yesterday",
    },
    {
        id: 3,
        name: "This week",
    },
    {
        id: 4,
        name: "Last 7 days",
    },
    {
        id: 5,
        name: "Last 14 days",
    },
    {
        id: 6,
        name: "Last 30 days",
    },
    {
        id: 7,
        name: "This month",
    },
    {
        id: 8,
        name: "Last month",
    },
    {
        id: 9,
        name: "This year",
    },
    {
        id: 10,
        name: "Last year",
    },
    {
        id: 11,
        name: "All time",
    },
];