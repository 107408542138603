import { Box, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import { useStyles } from "../style";

const DateColumns = ({ value, color }) => {
  const theme = useTheme();
  const isDarkMode = useTheme().palette.mode === "dark";
  const classes = useStyles({ theme, isDarkMode });
  return (
    <Typography
      sx={{
        color: color,
      }}
      className={classes.dateColumnContent}
    >
      {value || "-"}
    </Typography>
  );
};

const DurationColumns = ({ classes, value }) => {
  return (
    <Box className={classes.durationContent}>
      {value !== undefined && `${value} days`}
    </Box>
  );
};

const DateColumnsContent = ({ showHideList, classes, data }) => {
  const theme = useTheme();

  const { mode } = theme.palette;
  const isDarkTheme = mode === "dark";

  const {
    bg_color_1,
    bg_color_2,
    bg_color_3,
    bg_color_4,
    bg_color_5,
    bg_color_6,

    text_color_1,
    text_color_2,
    text_color_3,
    text_color_4,
    text_color_5,
    text_color_6,
  } = theme.lotstatusColorCodes;

  function getBackgroundColor() {
    return isDarkTheme ? "#1D1F2E" : "#FFFFFF";
  }

  function getColors() {
    return isDarkTheme ? "#FFFFFF" : "#5b5d6e";
  }

  const getDurationBgColor = (color1, color2) => {
    return `linear-gradient(to right, ${color1} 50%, ${color2} 50%)`;
  };

  const columnConfigs = [
    {
      key: "LotNumber",
      className: classes.columnContent,
      background: getBackgroundColor(),
      show: true,
      render: (value) => (
        <Typography
          variant="subtitle2"
          className={classes.themeColor}
          sx={{ fontWeight: 500 }}
        >
          {value}
        </Typography>
      ),
    },
    {
      key: "DieReceipt",
      className: classes.columnContent,
      background: bg_color_1,
      show: showHideList.dates,
      render: (value) => (
        <DateColumns
          value={value}
          color={isDarkTheme ? text_color_1 : theme.palette.text.primary}
        />
      ),
    },
    {
      key: "ReceiptBumpDuration",
      className: classes.contentDuration,
      background: getDurationBgColor(bg_color_1, bg_color_2),
      show: showHideList.duration,
      render: (value) => <DurationColumns classes={classes} value={value} />,
    },

    {
      key: "BumpIn",
      className: classes.columnContent,
      background: bg_color_2,
      show: showHideList.dates,
      render: (value) => <DateColumns value={value} color={text_color_2} />,
    },

    {
      key: "BumpDuration",
      className: classes.contentDuration,
      background: getDurationBgColor(bg_color_2, bg_color_2),
      show: showHideList.duration,
      render: (value) => <DurationColumns classes={classes} value={value} />,
    },

    {
      key: "BumpOut",
      className: classes.columnContent,
      background: bg_color_2,
      show: showHideList.dates,
      render: (value) => <DateColumns value={value} color={text_color_2} />,
    },

    {
      key: "BumpProbeDuration",
      className: classes.contentDuration,
      background: getDurationBgColor(bg_color_2, bg_color_3),
      show: showHideList.duration,
      render: (value) => <DurationColumns classes={classes} value={value} />,
    },

    {
      key: "ProbeIn",
      className: classes.columnContent,
      background: bg_color_3,
      show: showHideList.dates,
      render: (value) => <DateColumns value={value} color={text_color_3} />,
    },

    {
      key: "ProbeDuration",
      className: classes.contentDuration,
      background: getDurationBgColor(bg_color_3, bg_color_3),
      show: showHideList.duration,
      render: (value) => <DurationColumns classes={classes} value={value} />,
    },

    {
      key: "ProbeOut",
      className: classes.columnContent,
      background: bg_color_3,
      show: showHideList.dates,
      render: (value) => <DateColumns value={value} color={text_color_3} />,
    },

    {
      key: "ProbeAssemblyDuration",
      className: classes.contentDuration,
      background: getDurationBgColor(bg_color_3, bg_color_4),
      show: showHideList.duration,
      render: (value) => <DurationColumns classes={classes} value={value} />,
    },

    {
      key: "AssemblyIn",
      className: classes.columnContent,
      background: bg_color_4,
      show: showHideList.dates,
      render: (value) => <DateColumns value={value} color={text_color_4} />,
    },

    {
      key: "AssemblyDuration",
      className: classes.contentDuration,
      background: getDurationBgColor(bg_color_4, bg_color_4),
      show: showHideList.duration,
      render: (value) => <DurationColumns classes={classes} value={value} />,
    },

    {
      key: "AssemblyOut",
      className: classes.columnContent,
      background: bg_color_4,
      show: showHideList.dates,
      render: (value) => <DateColumns value={value} color={text_color_4} />,
    },

    {
      key: "AssemblyTestDuration",
      className: classes.contentDuration,
      background: getDurationBgColor(bg_color_4, bg_color_5),
      show: showHideList.duration,
      render: (value) => <DurationColumns classes={classes} value={value} />,
    },

    {
      key: "TestIn",
      className: classes.columnContent,
      background: bg_color_5,
      show: showHideList.dates,
      render: (value) => <DateColumns value={value} color={text_color_5} />,
    },

    {
      key: "TestDuration",
      className: classes.contentDuration,
      background: getDurationBgColor(bg_color_5, bg_color_5),
      show: showHideList.duration,
      render: (value) => <DurationColumns classes={classes} value={value} />,
    },

    {
      key: "TestOut",
      className: classes.columnContent,
      background: bg_color_5,
      show: showHideList.dates,
      render: (value) => <DateColumns value={value} color={text_color_6} />,
    },

    {
      key: "TestShipDuration",
      className: classes.contentDuration,
      background: getDurationBgColor(bg_color_5, bg_color_6),
      show: showHideList.duration,
      render: (value) => <DurationColumns classes={classes} value={value} />,
    },

    {
      key: "ShipOut",
      className: classes.columnContent,
      background: bg_color_6,
      show: showHideList.dates,
      render: (value) => <DateColumns value={value} color={text_color_6} />,
    },
  ];

  return (
    <>
      {columnConfigs.map(
        (column, index) =>
          column.show && (
            <Box
              data-testid={column.key}
              className={clsx(
                column.className,
                index === 0 && classes.stickyHeader
              )}
              key={column.key}
              sx={{
                background: isDarkTheme ? "#1D1F2E" : `${column.background}`,
              }}
            >
              <Box
                sx={{
                  borderRight:
                    index === 0 ? `1px solid ${getColors()}` : "none",
                }}
                className={
                  column.className === classes.columnContent
                    ? classes.titleContent
                    : classes.titleDiffContent
                }
              >
                {column.className === classes.contentDuration && (
                  <Box className={classes.durationHeaderContent} />
                )}
                {column.render(data[column.key])}
              </Box>
            </Box>
          )
      )}
    </>
  );
};

export default DateColumnsContent;
