import * as React from "react";
import Box from "@mui/material/Box";
import OverViewTitle from "components/supplyAndDemand/title";
import { useTheme } from "@emotion/react";
import TakaOverviewTable from "components/supplyAndDemand/overviewTable";
import CustomTabPanel from "components/supplyAndDemand/Tabs/customTabPanel";
import ParametricAnalysis from "pages/parametricAnalysis";
import { useParams } from "react-router";
import HelvetiaCompliancyCheck from "pages/helvetiaCompliancyCheck";
import OldHelvetiaOverView from "components/oldHelvetia/overView";
import { supplayAndDemandGetProductById } from "pages/supplyDemand/supplyDemand.service";

export default function SupplayAndDemandPageTabs({ isNavbarOpen }) {
  let { fid } = useParams();
  const [product, setProduct] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();
  let { pid } = useParams();

  const getProductById = React.useCallback(async () => {
    const data = await supplayAndDemandGetProductById({
      React,
      productId: pid,
    });

    if (data) {
      setProduct(data.items[0]);
    }
  }, [pid, setProduct]);

  React.useEffect(() => {
    if (!pid) return;
    setIsLoading(true);
    getProductById();
    setIsLoading(false);
  }, [pid, getProductById]);

  if (!product?.product_name) {
    return null;
  }

  return (
    <Box sx={{ width: "100%" }} data-testid={"supply-and-demand-tabs"}>
      <CustomTabPanel value={Number(fid)} index={1} isNavbarOpen={isNavbarOpen}>
        {!isLoading && Object.keys(product).length !== 0 && (
          <Box>
            <OverViewTitle
              theme={theme}
              title={
                product?.product_name ? product.product_name + " Overview" : ""
              }
            />
            <TakaOverviewTable product={product} />
          </Box>
        )}
      </CustomTabPanel>
      <CustomTabPanel isNavbarOpen={isNavbarOpen} value={Number(fid)} index={2}>
        <ParametricAnalysis productId={pid} isNavbarOpen={isNavbarOpen} />
      </CustomTabPanel>
      <CustomTabPanel isNavbarOpen={isNavbarOpen} value={Number(fid)} index={5}>
        {product?.product_name === "V93K" ? (
          <HelvetiaCompliancyCheck
            productId={pid}
            isNavbarOpen={isNavbarOpen}
          />
        ) : (
          <OldHelvetiaOverView
            productName={product?.product_name}
            isNavbarOpen={isNavbarOpen}
            disableVirtualization={false}
          />
        )}
      </CustomTabPanel>
    </Box>
  );
}
