import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  wrapper: {
    height: "auto",
    width: "200px",
    position: "absolute",
    top: "50px",
    right: "0px",
    padding: "5px 10px",
    borderRadius: "5px",
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 12px',
    cursor: "pointer",
    zIndex: "100",
    overflow: "auto",
    display: "flex",
    flexWrap: "wrap",
    background: ({ theme }) => theme.palette.bgColor,
  },
});
