import { Box, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import RenderSortIcons from "../utils/sortIcons";

const YieldHeadColumn = ({ sortBy, sortOrder, column, classes }) => {
  return (
    <Box className={classes.yieldHeaderWrapper}>
      <Typography
        className={clsx(classes.cursorptr, classes.themeColor)}
        variant="subtitle2"
        fontWeight={500}
      >
        {column.title}
      </Typography>
      {sortBy === column.key ? RenderSortIcons(sortOrder, classes) : null}
    </Box>
  );
};

const YieldHeaders = ({
  showHideList,
  classes,
  toggleSortOrder,
  sortOrder,
  sortBy,
}) => {
  const isDarkMode = useTheme().palette.mode === "dark";
  const yieldHeaders = [
    {
      key: "BumpYield",
      title: "BumpIn",
      className: classes.yieldColumnHeader,
      show: showHideList.yield,
      render: (column) => (
        <YieldHeadColumn
          sortBy={sortBy}
          sortOrder={sortOrder}
          column={column}
          classes={classes}
        />
      ),
    },

    {
      key: "ProbeYield",
      title: "ProbeIn",
      className: classes.yieldColumnHeader,
      show: showHideList.yield,
      render: (column) => (
        <YieldHeadColumn
          sortBy={sortBy}
          sortOrder={sortOrder}
          column={column}
          classes={classes}
        />
      ),
    },

    {
      key: "AssemblyYield",
      title: "AssemblyIn",
      className: classes.yieldColumnHeader,
      show: showHideList.yield,
      render: (column) => (
        <YieldHeadColumn
          sortBy={sortBy}
          sortOrder={sortOrder}
          column={column}
          classes={classes}
        />
      ),
    },

    {
      key: "TestYield",
      title: "TestIn",
      className: classes.yieldColumnHeader,
      show: showHideList.yield,
      render: (column) => (
        <YieldHeadColumn
          sortBy={sortBy}
          sortOrder={sortOrder}
          column={column}
          classes={classes}
        />
      ),
    },
  ];
  return (
    <>
      {yieldHeaders.map(
        (column, index) =>
          column.show && (
            <Box
              data-testid={column.key}
              className={column.className}
              onClick={() => toggleSortOrder(column.key)}
              key={column.key}
              sx={{
                borderLeft:
                  index === 0 &&
                  `3px solid ${isDarkMode ? "#FFFFFF" : "#5b5d6e"}`,
                borderRight: `1px solid ${isDarkMode ? "#FFFFFF" : "#5b5d6e"}`,
              }}
            >
              {column.render(column)}
            </Box>
          )
      )}
    </>
  );
};

export default YieldHeaders;
