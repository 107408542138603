// Context.js
import {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import constants from "../constants/constants.json";

export const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOption, setFilterOption] = useState({
    allproductType: [],
    allLinks: [],
    advanceFilter: false,
    productType: [],
    links: [],
    filterByFavorites: false,
    clearFilters: false,
    currentPage: 1,
  });
  const [sortOption, setSortOption] = useState("");
  const [showAdvanceFilterOptions, setShowAdvanceFilterOptions] =
    useState(false);
  const [selectedFilters, setSelectedFilters] = useState("");

  const handleFilterChange = useCallback(
    (e) => {
      if (!e?.target?.dataset) return;

      const { value } = e.target.dataset;

      if (value === "clearFilter") {
        setFilterOption({
          ...filterOption,
          advanceFilter: false,
          productType: [],
          links: [],
          filterByFavorites: false,
          clearFilters: true,
          currentPage: 1,
        });
      }

      if (value === constants.advanceFilters) {
        setFilterOption({
          ...filterOption,
          advanceFilter: false,
          filterByFavorites: false,
          clearFilters: false,
        });
        setShowAdvanceFilterOptions(true);
      } else if (value === constants.favorites) {
        setFilterOption({
          ...filterOption,
          productType: [],
          links: [],
          filterByFavorites: true,
          advanceFilter: false,
          clearFilters: false,
        });
      }
    },
    [filterOption]
  );

  const valueProps = useMemo(
    () => ({
      searchQuery,
      filterOption,
      sortOption,
      showAdvanceFilterOptions,
      setShowAdvanceFilterOptions,
      setSearchQuery: (e) => setSearchQuery(e.target.value),
      handleFilterChange,
      setFilterOption: (val) => setFilterOption(val),
      setSortOption: (e) => setSortOption(e.target.dataset.value),
      setSelectedFilters,
      selectedFilters,
    }),
    [
      filterOption,
      handleFilterChange,
      searchQuery,
      selectedFilters,
      showAdvanceFilterOptions,
      sortOption,
    ]
  );

  return (
    <AppContext.Provider value={valueProps}>{children}</AppContext.Provider>
  );
};
