import { cookiesGet } from "./tools";

export default function loadAuth(loadData) {
  let jwtToken = cookiesGet('jwtToken');

  if (jwtToken) return loadData();

  let timer = setTimeout(() => {
    loadData();
    clearTimeout(timer);
  })
}
