import { AppBar, Grid, Toolbar, Typography } from "@mui/material";
import { useStyles } from "./styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTheme } from "@emotion/react";
import XIcon from "@mui/icons-material/X";
import constants from "../../../constants/constants.json";

const Footer = () => {
  const isDarkTheme = useTheme().palette.mode === "dark";
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <AppBar
      position="sticky"
      classes={{ root: classes.appBar }}
      component={"footer"}
      data-testid={"footer"}
    >
      <Toolbar disableGutters>
        <div className={classes.footerWrapper}>
          <Grid className={classes.imgWrapper}>
            {isDarkTheme ? (
              <img
                className={classes.img}
                src={constants.assets.footerDarkLogo}
                alt={constants.advantest}
              />
            ) : (
              <img
                className={classes.img}
                src={constants.assets.footerLogo}
                alt={constants.advantest}
              />
            )}
          </Grid>
          <Grid className={classes.linkWrapper}>
            <a
              data-testid={constants.terms}
              href={constants.links.terms}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <Typography className={classes.text} variant="subtitle1">
                {constants.terms}
              </Typography>
            </a>

            <a
              data-testid={constants.contact}
              href={constants.links.contact}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <Typography className={classes.text} variant="subtitle1">
                {constants.contact}
              </Typography>
            </a>
            <a
              data-testid={constants.privacy}
              href={constants.links.privacy}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <Typography className={classes.text} variant="subtitle1">
                {constants.privacy}
              </Typography>
            </a>
          </Grid>
        </div>
        <div className={classes.btnWrapper}>
          <div className={classes.copyright}>
            <Typography variant="body1" className={classes.copyrightText}>
              {`© Copyright ${new Date().getFullYear()} ADVANTEST CORPORATION`}
            </Typography>
          </div>
          <div className={classes.icon}>
            <a
              data-testid={constants.facebook}
              href={constants.links.facebook}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon color="primary" className={classes.fbIcon} />
            </a>
            <a
              data-testid={constants.twitter}
              href={constants.links.twitter}
              target="_blank"
              rel="noreferrer"
            >
              <XIcon color="primary" className={classes.xicon} />
            </a>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
