import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    chip: {
        backgroundColor: ({ theme }) => theme.palette.bgColor,
        color: ({ theme }) => theme.palette.text.primary,
        border: '1px solid #9a0143',
        padding: '1% 1%',
        borderRadius: '15px',
        fontFamily: 'Rubik',
        textTransform: 'none',
        height: '2vh',
        minHeight: '20px',
        '&:hover': {
            backgroundColor: '#9a0143',
            color: '#fff'
        }
    },
})