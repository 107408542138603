import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
    calendarWrapper: {
        position: 'absolute',
        top: '50px',
        left: '0px',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 12px',
        width: '545px',
        padding: '10px',
        borderRadius: '10px',
        zIndex: '1000',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        backgroundColor: ({ theme }) => theme.palette.bgColor,
        color: ({ theme }) => theme.palette.text.primary,
    },
    checkboxFormGroup: {
        flexDirection: "row",
        display: "flex"
    },
    dateRangeContainer: {
        backgroundColor: "#565a75",
        width: '45%',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30px',
        borderRadius: '5px'
    },
    dateRangeText: {
        color: '#fff'
    },
    dateRangeFilterWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginTop: '10px',
        position: 'relative'
    },
    closeBtn: {
        backgroundColor: ({ theme }) => theme.palette.bgColor,
        color: ({ theme }) => theme.palette.text.primary,
        border: '1px solid #9a0143',
        padding: '1% 1%',
        borderRadius: '15px',
        textTransform: 'none',
        height: '2vh',
        minHeight: '20px',
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        '&:hover': {
            backgroundColor: '#9a0143',
            color: '#fff'
        }
    },
    calendar: {
        backgroundColor: ({ theme }) => theme.palette.bgColor,
        color: ({ theme }) => theme.palette.text.primary,
        boxShadow: 'none !important',
        '& .rmdp-range': {
            backgroundColor: '#ffeae7',
            boxShadow: '0 0 1px #8798ad !important',
            color: '#9a0143 !important'
        },
        '& .rmdp-week-day': {
            fontSize: '13px',
            fontWeight: '700',
            color: ({ theme }) => theme.palette.text.disabled
        },

        '& .rmdp-week > div:nth-child(7)': {
            color: '#9a0143'
        },
        '& .rmdp-week > div:nth-child(1)': {
            color: '#9a0143'
        },
        '& .rmdp-day.rmdp-today > span': {
            backgroundColor: '#9a0143',
            borderRadius: '4px',
            color: '#fff',
        },
        '& .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover': {
            backgroundColor: '#9a0143 !important',
            borderRadius: '4px !important'
        },
        '& .rmdp-header-values': {
            backgroundColor: '#565a75',
            color: '#fff !important',
            padding: '3px 5px',
            borderRadius: '5px'
        },
        '& .rmdp-day-picker > div:nth-child(1)': {
            border: '1px solid #a8abc2',
            borderRadius: '5px',
            padding: '5px'
        },
        '& .rmdp-day-picker > div:nth-child(2)': {
            border: '1px solid #a8abc2',
            borderRadius: '5px',
            padding: '5px'
        },
        '& .rmdp-arrow-container': {
            height: '30px !important',
            width: '30px !important',
            display: 'flex !important',
            justifyContent: 'center !important',
            alignItems: 'center !important',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.3) !important'
        },
        '& .rmdp-arrow-container:hover': {
            backgroundColor: '#fff !important',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
            border: '1px solid #565a75 !important'
        },
        '& .rmdp-right > i': {
            marginRight: '0px !important',
            '-webkit-transform': 'rotate(0 deg)'
        },
        '& .rmdp-arrow': {
            borderColor: '#727272 !important',
            marginTop: '0px !important'
        },
        '& .rmdp-wrapper': {
            backgroundColor: ({ theme }) => theme.palette.bgColor
        },
        '& .rmdp-week > .rmdp-day': {
            color: ({ theme }) => theme.palette.text.secondary
        }
    }
});
