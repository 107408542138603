import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTheme } from "@emotion/react";
import { useStyles } from "./styles";
import { Box, Stack, Button, Typography } from "@mui/material";
import BasicTable from "components/table";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import _ from "lodash";
import Filters from "./filters";
import { getTimeStamp } from "utils/tools";

const projectNames = ["Python", "Donau", "System Control Board"];

const compliantToShipment = ["Yes", "No", "Unknown"];
const partNumbers = [
  "EK601-66600",
  "EK751-66600",
  "EK751-66610",
  "EK751-66620",
  "EK050-66450",
  "EK050-66451",
  "EK050-66442",
];
const productTypes = [
  "XPS256",
  "XPS128",
  "XPS128+HV",
  "PS5000",
  "System Control Board",
  "Side Board",
];

const data = {
  projectNames,
  compliantToShipment,
  partNumbers,
  productTypes,
};

const startDate = new Date();
const today = new Date();
startDate.setDate(today.getDate() - 14);
const endDate = new Date();

const OldHelvetiaOverView = (props) => {
  const theme = useTheme();
  const classes = useStyles({ theme, isNavbarOpen: props.isNavbarOpen });
  const [queryData, setQueryData] = useState([]);
  const [tableRows, setTableRows] = useState(queryData);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [queryTime, setQueryTime] = useState(new Date());
  const [params] = useState(new URLSearchParams());
  const dateFormat = "YYYY-MM-DD";
  const [filter, setFilter] = useState({
    compliantToShipment,
    partNumbers,
    productTypes,
    search: "",
    dateRange: {
      start: getTimeStamp(startDate, dateFormat),
      end: getTimeStamp(endDate, dateFormat),
    },
    projectNames: [props.productName],
  });

  const defaultSort = { field: "serialNumber", sort: "asc" };
  const [sort, setSort] = useState([defaultSort]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const onPaginationModelChange = ({ page, pageSize }) => {
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const onSortModelChange = (event) => {
    if (JSON.stringify(event) !== JSON.stringify(sort)) {
      setSort(event);
    }
  };

  const clearFilter = () => {
    setFilter({
      search: "",
      dateRange: {
        start: getTimeStamp(startDate, dateFormat),
        end: getTimeStamp(endDate, dateFormat),
      },
      compliantToShipment,
      partNumbers,
      productTypes,
      projectNames: [props.productName],
    });
  };

  const debouncedOnChange = useMemo(
    () =>
      _.debounce((value) => {
        setSearchQuery(value);
      }, 300),
    []
  );

  const onSearchChange = (e) => {
    const value = e?.target?.value;
    setFilter({
      ...filter,
      search: value,
    });
  };

  useEffect(() => {
    debouncedOnChange(filter.search);
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange, filter.search]);

  const getIcons = useCallback(
    (status) => {
      switch (status.toLowerCase()) {
        case "yes":
          return <CheckBoxIcon className={classes.statusYes} />;
        case "no":
          return <DisabledByDefaultIcon className={classes.statusNo} />;
        default:
          return <HelpCenterIcon className={classes.unknownStatus} />;
      }
    },
    [classes]
  );

  const headerCells = [
    {
      field: "serialNumber",
      headerName: "Serial Number",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "partNumber",
      headerName: "Part Number",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productType",
      headerName: "Product Type",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "projectName",
      headerName: "Project Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastTestDate",
      headerName: "Last Test Date",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <p>{getTimeStamp(params.formattedValue, "DD.MM.YYYY - HH:mm:ss")}</p>
        );
      },
    },
    {
      field: "compliantToShipment",
      headerName: "Compliant To Shipment",
      flex: 1.5,
      align: "center",
      headerAlign: "center",

      renderCell: (params) => (
        <div className={classes.compliancystatus}>
          {getIcons(params.formattedValue)}
          <p className={classes.statusText}>{params.formattedValue}</p>
        </div>
      ),
    },
    {
      field: "button",
      headerName: "DETAILS",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Button
          data-testid="viewDetails"
          onClick={() => {
            console.log("Details");
          }}
          className={classes.detailbtn}
        >
          View
        </Button>
      ),
    },
  ];

  const isCompliantToShipment = (val) => {
    if (val.Compliant === "true") {
      return "Yes";
    }
    if (val.Compliant === "false") {
      return "No";
    }

    return "Unknown";
  };

  const apiCall = (params) => {
    React.$fetch(React.$apis.helvetiaOverview, params)
      .then((res) => {
        if (res.status === 200) {
          setQueryData(
            res.data.body.map((d) => {
              return {
                id: d.HwComponentId,
                serialNumber: d.HlaSerialNumber,
                partNumber: d.HlaPartNumber,
                productType: d.ProductType,
                projectName: d.ProjectName,
                compliantToShipment: isCompliantToShipment(d),
                lastTestDate: d.DateLastTest,
                componentId: d.MasterHwComponentInTreeIdLast,
                compliancyLog: d.CompliancyLog,
              };
            })
          );

          setIsLoading(false);
          setQueryTime(new Date());
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const setSearchInParams = () => {
    if (filter.search) {
      params.append("hlaserialnumber", filter.search);
    }
  };

  const setProjectNamesInParams = () => {
    if (filter.projectNames.length) {
      filter.projectNames.forEach((n) => params.append("projectname", n));
    }
  };

  const setCompliantToShipmentInParams = () => {
    if (filter.compliantToShipment.length) {
      filter.compliantToShipment.forEach((comp) => {
        if (comp.toLowerCase() === "yes") {
          params.append("compliant", true);
        }
        if (comp.toLowerCase() === "no") {
          params.append("compliant", false);
        }
        if (comp.toLowerCase() === "unknown") {
          params.append("compliant", "unknown");
        }
      });
    }
  };

  const setDateRangeInParams = () => {
    if (filter.dateRange.start && filter.dateRange.end) {
      params.append(
        "startdate",
        new Date(filter.dateRange.start).toISOString()
      );
      params.append("enddate", new Date(filter.dateRange.end).toISOString());
    }
  };

  const query = async () => {
    setIsLoading(true);

    setSearchInParams();

    setProjectNamesInParams();

    if (filter.projectNames.length) {
      filter.projectNames.forEach((n) => params.append("projectname", n));
    }
    setDateRangeInParams();

    if (filter.partNumbers.length) {
      filter.partNumbers.forEach((part) =>
        params.append("hlapartnumber", part)
      );
    }

    if (filter.productTypes.length) {
      filter.productTypes.forEach((type) => params.append("producttype", type));
    }

    setCompliantToShipmentInParams();

    apiCall(params);
  };

  useEffect(() => {
    query();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    let newItems = queryData.filter((rowData) => {
      return (
        rowData.serialNumber !== null &&
        rowData.serialNumber.includes(filter.search) &&
        filter.partNumbers.includes(rowData.partNumber) &&
        filter.productTypes.includes(rowData.productType) &&
        filter.projectNames.includes(rowData.projectName) &&
        filter.compliantToShipment.includes(rowData.compliantToShipment) &&
        new Date(filter.dateRange.start).getTime() <=
          new Date(rowData.lastTestDate).getTime() &&
        new Date(filter.dateRange.end).getTime() >=
          new Date(rowData.lastTestDate).getTime()
      );
    });

    if (filter.search) {
      newItems = _.filter(newItems, (i) =>
        _.includes(i.serialNumber, filter.search)
      );
    }

    //partNumbers

    newItems = _.filter(newItems, (i) =>
      _.includes(filter.partNumbers, i.partNumber)
    );

    //prodctType
    newItems = _.filter(newItems, (i) =>
      _.includes(filter.productTypes, i.productType)
    );

    //project
    newItems = _.filter(newItems, (i) =>
      _.includes(filter.projectNames, i.projectName)
    );

    // compliantToShipment
    newItems = _.filter(newItems, (i) =>
      _.includes(filter.compliantToShipment, i.compliantToShipment)
    );

    setTableRows(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchQuery,
    filter.compliantToShipment,
    filter.dateRange,
    filter.partNumbers,
    filter.productTypes,
    filter.projectNames,
    isLoading,
  ]);

  const onDropDownChange = useCallback(
    (type) => (e) => {
      if (!type || !e?.target?.value) return;
      const value = e.target.value;

      if (value.includes("checkAll")) {
        setFilter({
          ...filter,
          [type]: data[type],
        });
        return;
      }

      if (value.includes("unCheckAll")) {
        setFilter({
          ...filter,
          [type]: [],
        });
        return;
      }

      setFilter({
        ...filter,
        [type]: typeof value === "string" ? value.split(",") : value,
      });
    },
    [filter]
  );

  const onDateChange = ({ start, end }) => {
    setFilter({
      ...filter,
      dateRange: {
        start,
        end,
      },
    });
  };

  const downloadCSV = () => {
    const dataToDownload = tableRows.map((t) => {
      return [
        t.serialNumber.toString(),
        t.partNumber,
        t.productType,
        t.projectName,
        t.lastTestDate,
        t.compliantToShipment,
      ];
    });

    const headers = headerCells.map((c) => c.headerName);
    headers.pop();

    let csvContent = headers.join(",") + "\n";
    dataToDownload.forEach((t) => {
      if (t.length) {
        csvContent += t.join(",") + "\n";
      }
    });

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Helvetia Compliancy Data - ${getTimeStamp(new Date())}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <Stack
      sx={{
        marginTop: "2rem",
      }}
    >
      <Box>
        <Filters
          query={query}
          isLoading={isLoading}
          filter={filter}
          setFilter={setFilter}
          clearFilter={clearFilter}
          partNumbers={partNumbers}
          productTypes={productTypes}
          projectNames={projectNames}
          compliantToShipment={compliantToShipment}
          onSearchChange={onSearchChange}
          onDropDownChange={onDropDownChange}
          onDateChange={onDateChange}
          downloadCSV={downloadCSV}
          isNavbarOpen={props.isNavbarOpen}
        />
      </Box>
      <Box className={classes.tableWrapper}>
        <BasicTable
          columns={headerCells}
          rows={tableRows}
          onSortModelChange={onSortModelChange}
          sortModel={sort}
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          disableColumnMenu={true}
          isNavbarOpen={false}
          isLoading={isLoading}
          disableVirtualization={props.disableVirtualization}
        />
      </Box>
      <Box className={classes.lastQueryTimeContainer}>
        <Typography className={classes.lastQueryTimeText}>
          Last update from Database on {queryTime.toLocaleDateString("de-DE")}{" "}
          at {queryTime.toLocaleTimeString("de-DE")}
        </Typography>
      </Box>
    </Stack>
  );
};

export default OldHelvetiaOverView;
