import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import BasicTable from "components/table";
import React, { useCallback, useEffect, useState } from "react";
import constants from "../../constants/constants.json";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { useNavigate, useParams } from "react-router";
import { getOverviewLevel3 } from "./parametricAnalysis.services";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import PropTypes from "prop-types";
import { find } from "lodash";

const ParametricAnalysis = ({
  productId,
  isNavbarOpen,
  disableVirtualization = false,
}) => {
  const [openPlayer, setOpenPlayer] = useState(false);
  const [ifrUrl, setIfrUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isWebPlayerLoading, setIsWebPlayerLoading] = useState(false);

  const [data, setData] = useState([]);
  const theme = useTheme();
  const [spotFireIntegration, setSpotFireIntegration] = useState({});
  const navigate = useNavigate();
  const { pid, fid, tid } = useParams();

  const defaultSort = { field: "Name", sort: "asc" };
  const [sort, setSort] = useState([defaultSort]);
  const [parametricPaginationModel, setParametricPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const onPaginationModelChange = ({ page, pageSize }) => {
    setParametricPaginationModel({
      page,
      pageSize,
    });
  };

  const onSortModelChange = (event) => {
    if (JSON.stringify(event) !== JSON.stringify(sort)) {
      setSort(event);
    }
  };

  const location = useLocation();
  const queryParams = useCallback(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const getTemplateName = () => {
    try {
      const templateValue = queryParams().get("template");
      if (templateValue) {
        return queryParams().get("template").split("/").pop();
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const tidName = decodeURIComponent(getTemplateName());

  const handle = useFullScreenHandle();

  const classes = useStyles({
    theme,
    isNavbarOpen,
    openPlayer,
    isWebPlayerExpanded: handle.active,
  });

  const onBackClick = useCallback(() => {
    navigate(`/overview/entry/${pid}/${Number(fid)}`);
    setOpenPlayer(false);
  }, [pid, fid, navigate]);

  const handleViewWebPlayerClick = (value) => {
    navigate(
      `/overview/entry/${pid}/${Number(fid)}?template=${value.row.Detail.link}`
    );

    if (value?.row?.Name === "Spotifire Integration Test") {
      setSpotFireIntegration({
        baseUrl: "https://eu.spotfire-next.cloud.tibco.com/spotfire/wp/",
        // baseUrl: "https://api.siemens.glasswall.advantest.com/spotfire/wp/",
        analysisPath: value.row.Detail.link,
      });
      setIfrUrl("");
    } else {
      setIfrUrl(React.$views.wpTmplUrl + value.row.Detail.link.slice(1));
      setSpotFireIntegration({});
    }
    setOpenPlayer(true);
  };

  const columns = [
    { field: "Name", headerName: "Name", flex: 0.07 },
    {
      field: "Description",
      headerName: "Description",
      flex: 0.1,
    },
    {
      field: "Documentation	",
      headerName: "Documentation",
      flex: 0.05,
      renderCell: (value) => {
        return (
          <Link
            href={value.row.Documentation.link}
            target="_blank"
            sx={{ color: theme.palette.text.primary }}
          >
            {value.row.Documentation.label}
          </Link>
        );
      },
      valueGetter: (params) => params.row.Documentation.label,
      sortComparator: (v1, v2) => v2.localeCompare(v1),
    },
    {
      field: "Version",
      headerName: "Version",
      flex: 0.03,
    },
    {
      field: "Details",
      headerName: "Details",
      flex: 0.05,
      sortable: false,
      headerClassName: classes.detailsHeader,
      renderCell: (value) => {
        return (
          <Button
            data-testid={"webplayer-btn"}
            onClick={() => handleViewWebPlayerClick(value)}
            className={classes.webplayerBtn}
            variant="contained"
          >
            {constants.webplayer}
          </Button>
        );
      },
    },
  ];

  const getTableData = useCallback(async () => {
    try {
      setIsLoading(true);
      const templateValue = queryParams().get("template");

      let res = await getOverviewLevel3({
        React,
        productId,
        template: templateValue,
      });
      if (res) setData(res.tbody);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [productId, queryParams]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onReadyCallback = (response, newApp) => {
    if (response.status === "OK") {
      console.log(
        "OK received. Opening document to page 0 in element renderAnalysis"
      );
      newApp.openDocument("spotfire-container", 0);
    } else {
      console.log(
        "Status not OK. " + response.status + ": " + response.message
      );
      setIsWebPlayerLoading(false);
    }
  };

  const onLoginElement = () => {
    console.log("Login needed");
    const elm = document.querySelector("body > div:nth-child(7)");
    if (elm) {
      elm.onclick = () => {
        elm.remove();
        window.location.reload();
      };
    } else {
      const timer = setInterval(() => {
        const loginElm = document.querySelector("body > div:nth-child(7)");
        if (loginElm) {
          loginElm.onclick = () => {
            loginElm.remove();
            window.location.reload();
          };
          clearInterval(timer);
        }
      }, 1000);
    }
    return null;
  };

  const getIframe = useCallback(
    (margin) => {
      if (ifrUrl) {
        return (
          <div
            className={classes.iframeWrapper}
            data-testid={"webplayer-iframe"}
          >
            <iframe
              style={{ marginTop: margin }}
              className={classes.iframe}
              src={ifrUrl}
              title="webplayer"
            ></iframe>
          </div>
        );
      }

      if (spotFireIntegration?.baseUrl && spotFireIntegration?.analysisPath) {
        return (
          <div
            data-testid="spotfire-container"
            id="spotfire-container"
            style={{ marginTop: margin }}
            className={classes.iframe}
          ></div>
        );
      }
      return <></>;
    },
    [
      ifrUrl,
      spotFireIntegration?.baseUrl,
      spotFireIntegration?.analysisPath,
      classes.iframeWrapper,
      classes.iframe,
    ]
  );

  // style for login screen

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.spotfire &&
      spotFireIntegration?.baseUrl &&
      spotFireIntegration?.analysisPath
    ) {
      const style = document.createElement("style");
      style.id = "custom-style-spotfire-webplayer";
      style.innerHTML = `
      body > div:nth-child(7) {
        z-index: 12345;
      }
      body > div:nth-child(7) > div {
        left: calc(56% - 200px) !important;
      }
    `;
      // Append the style element to the document head
      document.head.appendChild(style);

      // Cleanup the style element when the component unmounts
      return () => {
        const existingStyle = document.getElementById(
          "custom-style-spotfire-webplayer"
        );
        if (existingStyle) {
          document.head.removeChild(existingStyle);
        }
      };
    }
  }, [spotFireIntegration?.analysisPath, spotFireIntegration?.baseUrl]);

  useEffect(() => {
    const webPlayerServerRootUrl = spotFireIntegration.baseUrl;
    const analysisPath = spotFireIntegration.analysisPath;
    const parameters = "";
    const reloadInstances = true;
    const apiVersion = "7.14";
    const customizationInfo = undefined;
    if (
      typeof window !== "undefined" &&
      window.spotfire &&
      spotFireIntegration?.baseUrl &&
      spotFireIntegration?.analysisPath
    ) {
      document.getElementById("openProxyFrame")?.remove();
      setIsWebPlayerLoading(true);
      window.spotfire.webPlayer.createApplication(
        webPlayerServerRootUrl,
        customizationInfo,
        analysisPath,
        parameters,
        reloadInstances,
        apiVersion,
        onReadyCallback,
        onLoginElement
      );

      const iframe = document.querySelector("#spotfire-container > iframe");
      if (iframe) {
        iframe.onload = () => setIsWebPlayerLoading(false);
      } else {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            Array.from(mutation.addedNodes).forEach((node) => {
              if (node.tagName === "IFRAME") {
                node.onload = () => setIsWebPlayerLoading(false);
                observer.disconnect();
              }
            });
          });
        });

        observer.observe(document.querySelector("#spotfire-container"), {
          childList: true,
        });

        return () => {
          observer.disconnect();
          setIsWebPlayerLoading(false);
        };
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotFireIntegration]);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    const templateValue = queryParams().get("template");
    const template = find(data, ["Detail.link", templateValue]);
    if (templateValue && template?.Detail.link) {
      handleViewWebPlayerClick({ row: template });
    } else {
      onBackClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBackClick, tid, data.length]);

  return (
    <>
      {!openPlayer ? (
        <Box
          className={classes.parametricContainer}
          data-testid={"parametric-analysis"}
        >
          <Box className={classes.tableWrapper}>
            <BasicTable
              data-testid={"data-grid"}
              columns={columns}
              onSortModelChange={onSortModelChange}
              sortModel={sort}
              paginationModel={parametricPaginationModel}
              onPaginationModelChange={onPaginationModelChange}
              rows={data}
              disableColumnMenu={true}
              disableVirtualization={disableVirtualization}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      ) : (
        <Box className={classes.webplayerWrapper}>
          <Grid
            display={"flex"}
            justifyContent={"space-around"}
            className={classes.webplayerHeaderGrid}
          >
            <Box className={classes.tidNameWrapper}>
              <Typography variant="h5" className={classes.tidName}>
                {tidName}
              </Typography>
            </Box>
            <Box className={classes.btnContainer}>
              <Button
                data-testid={"back-btn"}
                className={classes.backBtn}
                variant="contained"
                onClick={onBackClick}
              >
                <ArrowLeftIcon fontSize="small" className={classes.backIcon} />
                Back
              </Button>
              <Button
                data-testid={"expand-btn"}
                sx={{ m: 0, p: 0, minWidth: 40 }}
                size="small"
                variant="contained"
                className={classes.expandIcon}
                onClick={handle.enter}
              >
                <AspectRatioIcon fontSize="small" />
              </Button>
            </Box>
          </Grid>
          {isWebPlayerLoading && (
            <Box
              sx={{
                marginTop: "10%",
              }}
            >
              <h1>Loading...</h1>
            </Box>
          )}
          <FullScreen handle={handle}>{getIframe("0")}</FullScreen>
        </Box>
      )}
    </>
  );
};

// props validation

ParametricAnalysis.propTypes = {
  productId: PropTypes.any,
  isNavbarOpen: PropTypes.bool,
  disableVirtualization: PropTypes.bool,
};

export default React.memo(ParametricAnalysis);
