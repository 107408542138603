import { Box, Pagination, TablePagination, useTheme } from "@mui/material";
import clsx from "clsx";
import { useStyles } from "../style";

const CustomPagination = ({
  paginationFilterLogic,
  totalFilterPages,
  totalPages,
  currentPage,
  handlePageChange,
  dataPerPageState,
  onRowsPerPageChange,
}) => {
  const theme = useTheme();
  const isDarkMode = useTheme().palette.mode === "dark";
  const classes = useStyles({ theme, isDarkMode });
  return (
    <Box
      className={clsx(
        classes.customPaginationContainer,
        classes.themeBackgroundColor,
        classes.themeColor
      )}
    >
      <Box className={classes.paginationWrapper}>
        <Pagination
          count={totalFilterPages}
          siblingCount={0}
          page={currentPage}
          shape="circular"
          onChange={handlePageChange}
          sx={{
            fontSize: "0.8vw !important",
            "& .MuiPaginationItem-root": {
              fontSize: "0.8vw !important",
            },
            "& .MuiButtonBase-root-MuiPaginationItem-root": {
              height: "2vw",
              width: "2vw",
              borderRadius: "50%",
            },

            "& MuiSvgIcon-root-MuiPaginationItem-icon": {
              fontSize: "1.2vw",
            },
          }}
        />

        <TablePagination
          component="div"
          count={totalPages}
          //hide default pagination action
          slotProps={{
            actions: {
              nextButton: { style: { display: "none" } },
              previousButton: { style: { display: "none" } },
            },
          }}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          rowsPerPage={dataPerPageState}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Box>
    </Box>
  );
};

export default CustomPagination;
