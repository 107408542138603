import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  containerBox: {
    minHeight: '700px',
    backgroundColor: ({ theme }) => theme.palette.themeColor,
  },
  pageContainer: {
    backgroundColor: ({ theme }) => theme.palette.themeColor,
  },
  pageWrapper: {
    position: "relative",
    height: "100%",
    paddingBottom: "1%",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    marginTop: "5rem",
    marginRight: "1rem",
    marginLeft: "1rem",
    marginBottom: "4rem",

    width: ({ isNavbarOpen }) => (isNavbarOpen ? "80%" : "90%"),
    "@media (max-width: 599px)": {
      margin: ({ isNavbarOpen }) =>
        isNavbarOpen ? "5rem 0rem 0rem 8rem" : "5rem 0rem 0rem 6.5rem",
    },

    "@media (min-width: 600px) and (max-width: 899px)": {
      margin: ({ isNavbarOpen }) =>
        isNavbarOpen ? "5rem 0rem 0rem 9rem" : "3rem 0rem 0rem 7rem",
    },

    "@media (min-width: 900px) and (max-width: 1199px)": {
      margin: ({ isNavbarOpen }) =>
        isNavbarOpen ? "5rem 0rem 0rem 9rem" : "5rem 0rem 0rem 7rem",
    },
    "@media (min-width: 1200px) and (max-width: 1920px)": {
      margin: ({ isNavbarOpen }) =>
        isNavbarOpen ? "3rem 0.5rem 0rem 4rem" : "3rem 0.5rem 0rem 5rem",
    },
    "@media (min-width: 1921px)": {
      margin: ({ isNavbarOpen }) =>
        isNavbarOpen ? "3rem 0.5rem 0rem 4rem" : "3rem 0.5rem 0rem 5rem",
    },
  },

  tableText: {
    color: ({ theme }) => theme.palette.text.primary,
  },
});
