import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  tabsWrapper: {
    borderBottom: "none",
    borderColor: "divider",
    position: "absolute",
  },
  hiddenTab: {
    display: "hidden",
    width: "5rem",
  },
  tabsHeader: {
    position: 'fixed',
    top: '77px'
  },
  tabsHeaderContainer: {
    bottom: '38%',
    left: '28%',
    position: 'absolute'
  }
});
