import {
  Box,
  CssBaseline,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
} from "@mui/material";
import expandArrow from "../../../assets/icons/expandArrow.svg";
import darkExpand from "../../../assets/icons/darkExpand.svg";
import darkCollapse from "../../../assets/icons/darkCollapse.svg";
import collapseArrow from "../../../assets/icons/collapseArrow.svg";
import MuiDrawer from "@mui/material/Drawer";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";
import constants from "../../../constants/constants.json";
import PropTypes from "prop-types";
import { removeAuth } from "utils/cache";

const drawerWidth = "16vw";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 2vw)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} +10px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const getNavBarToggleIconSrc = (isExpanded, toggleTheme) => {
  if (toggleTheme) {
    return isExpanded ? collapseArrow : expandArrow;
  } else {
    return isExpanded ? darkCollapse : darkExpand;
  }
};

const getLogoSrc = (isExpanded, toggleTheme) => {
  if (isExpanded) {
    return toggleTheme ? constants.assets.logo : constants.assets.logoDark;
  } else {
    return toggleTheme
      ? constants.assets.glasswall
      : constants.assets.glasswallDark;
  }
};

const NavBar = ({
  setMode,
  onNavBarExpand,
  onNavBarCollapse,
  user,
  logout,
}) => {
  const [toggleTheme, setToggleTheme] = useState(
    localStorage.getItem("theme") === "light"
  );
  const [isExpanded, setIsExpanded] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles({ isExpanded, theme });

  const handleThemeToggle = () => {
    setToggleTheme((prev) => !prev);
    setMode(toggleTheme ? "dark" : "light");
  };

  const handleNavBarToggle = () => {
    setIsExpanded((prev) => !prev);
    isExpanded ? onNavBarCollapse() : onNavBarExpand();
  };

  const handleNavigation = (route) => navigate(route);

  const handleLogout = () => {
    removeAuth();
    logout({ returnTo: window.location.origin });
  };

  const renderIcon = (icon) => (
    <ListItemIcon
      sx={{
        "& .MuiSvgIcon-root": { fontSize: "1.3vw" },
      }}
      className={classes.listItemIcon}
    >
      {icon}
    </ListItemIcon>
  );

  const renderListItem = (testId, icon, text, onClick, title = "") => (
    <ListItem sx={{ display: "block", py: 0 }}>
      <ListItemButton
        data-testid={testId}
        className={classes.listItemBtn}
        onClick={onClick}
      >
        {renderIcon(icon)}
        <ListItemText
          primary={text}
          title={title}
          sx={{ opacity: isExpanded ? 1 : 0 }}
          primaryTypographyProps={{
            color: (theme) => theme.palette.text.primary,
          }}
          classes={{ primary: classes.listItemText, root: classes.text }}
        />
      </ListItemButton>
    </ListItem>
  );

  return (
    <Box sx={{ display: "flex" }} data-testid={"navBar"}>
      <CssBaseline />
      <Drawer
        anchor="top"
        data-testid="drawer"
        classes={{ paper: classes.drawerContainer, root: classes.drawerRoot }}
        variant="permanent"
        open={isExpanded}
        sx={{
          ".MuiDrawer-paper": {
            backgroundColor: (theme) => theme.palette.bgColor,
          },
        }}
      >
        <div className={classes.container}>
          <div className={classes.actionContainer}>
            <IconButton
              data-testid="toggleTheme"
              onClick={handleThemeToggle}
              title={`Switch to ${toggleTheme ? "dark" : "light"} mode`}
            >
              {toggleTheme ? (
                <Brightness4Icon
                  data-testid="darkModeIcon"
                  sx={{ color: "#1D1F2E", height: "1.2vw", width: "1.2vw" }}
                />
              ) : (
                <Brightness7Icon
                  data-testid="lightModeIcon"
                  sx={{ height: "1.2vw", width: "1.2vw" }}
                />
              )}
            </IconButton>

            <button
              className={classes.buttonWrapper}
              onClick={handleNavBarToggle}
            >
              <img
                className={classes.expand}
                src={getNavBarToggleIconSrc(isExpanded, toggleTheme)}
                alt={isExpanded ? "Collapse" : "Expand"}
                data-testid={isExpanded ? "navBarCollapse" : "navBarExpand"}
              />
            </button>
          </div>
          <div>
            <button
              className={classes.buttonWrapper}
              onClick={() => handleNavigation(constants.routes.home)}
            >
              <img
                className={classes.logo}
                src={getLogoSrc(isExpanded, toggleTheme)}
                alt={constants.glasswall}
                data-testid="logo"
              />
            </button>
          </div>

          <Divider classes={{ root: classes.bar }} variant="middle" />
          <Box className={classes.drawerContent} flexDirection={"column"}>
            <Typography
              className={classes.profile}
              variant="subtitle2"
              gutterBottom
              component="div"
            >
              {constants.profile}
            </Typography>
            <div>
              <List>
                {renderListItem("profile", <PersonIcon />, user.email, () =>
                  handleNavigation(constants.routes.profile)
                )}
                {renderListItem(
                  "services",
                  <MiscellaneousServicesIcon />,
                  constants.services,
                  () => window.open(constants.links.services, "_blank")
                )}
                {renderListItem(
                  "help",
                  <HelpOutlineIcon />,
                  constants.help,
                  () => window.open(constants.links.help, "_blank"),
                  "Link to User Documentation"
                )}
                {renderListItem(
                  "logout",
                  <LogoutIcon />,
                  constants.logout,
                  handleLogout
                )}
              </List>
            </div>
            <Divider
              sx={{ mt: "70%" }}
              classes={{ root: classes.bar }}
              variant="middle"
            />
          </Box>

          <div className={classes.margin}>
            <Typography classes={{ root: classes.version }}>
              {isExpanded
                ? `${constants.gwVersion}: ${constants.gwVersionValue}`
                : `v${constants.gwVersionValue}`}
            </Typography>
          </div>
        </div>
      </Drawer>
    </Box>
  );
};

NavBar.propTypes = {
  setMode: PropTypes.func.isRequired,
  onNavBarExpand: PropTypes.func.isRequired,
  onNavBarCollapse: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
};

export default NavBar;
