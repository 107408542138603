const getSortValue = (valueA, valueB, sortOrder) => {
  // Handle empty or undefined values
  if (!valueA && !valueB) return 0;
  if (!valueA) return sortOrder === "desc" ? 1 : -1;
  if (!valueB) return sortOrder === "desc" ? -1 : 1;

  return null; // Indicate that both values are present and non-empty
};

const compareValues = (valueA, valueB, sortOrder) => {
  if (typeof valueA === "number" && typeof valueB === "number") {
    return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
  } else {
    return sortOrder === "asc"
      ? String(valueA).localeCompare(String(valueB))
      : String(valueB).localeCompare(String(valueA));
  }
};

const sortData = (dataArray, columnName, sortOrder, copydata) => {
  if (columnName === "" || sortOrder === "initial") {
    return dataArray;
  }

  return copydata.sort((a, b) => {
    const valueA = a[columnName];
    const valueB = b[columnName];

    const sortValue = getSortValue(valueA, valueB, sortOrder);
    if (sortValue !== null) return sortValue;

    return compareValues(valueA, valueB, sortOrder);
  });
};

const ColumnSort = (dataArray, columnName, sortOrder, copydata) => {
  if (sortOrder !== "asc" && sortOrder !== "desc" && sortOrder !== "initial") {
    return;
  }
  return sortData(dataArray, columnName, sortOrder, copydata);
};

export default ColumnSort;
