import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  label: {
    color: "#5b5d6e !important",
    fontFamily: "Rubik !important",
    fontSize: "0.8vw",
    fontWeight: 400,
    fontStyle: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (min-width: 2080px) and (max-width: 2700px)": {
      top: "-10%",
    },
    "@media (min-width: 2701px) and (max-width: 2900px)": {
      top: "-20%",
    },
    "@media (min-width: 2901px) and (max-width: 3800px)": {
      top: "-30%",
    },
    "@media (min-width: 700px) and (max-width: 1100px)": {
      top: "10%",
    },
  },
  form: {
    width: "10vw",
    display: "flex",
    height: "35px",
    justifyContent: "center",
  },
  checkbox: {
    color: ({ isDarkTheme }) => (!isDarkTheme ? "rgb(118, 118, 118)" : "#fff"),
    stroke: "rgb(118, 118, 118)",
    strokeWidth: "0.1px",
    "&.Mui-checked": {
      color: "#9a0143",
    },
    "&.MuiCheckbox-root": {
      zIndex: "1",
    },
    "&.MuiCheckbox-root.Mui-checked::before": {
      content: "''",
      width: "10px",
      height: "10px",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      background: "#fff",
      zIndex: "-1",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
    },
  },
});
