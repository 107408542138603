import { Box } from "@mui/material";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";

const RenderSortIcons = (sortOrder, classes) => {
  if (sortOrder === "asc") {
    return (
      <Box
        data-testid="ascArrow"
        component={"span"}
        className={classes.arrowIcon}
      >
        <FaArrowUp />
      </Box>
    );
  } else if (sortOrder === "desc") {
    return (
      <Box
        data-testid="descArrow"
        component={"span"}
        className={classes.arrowIcon}
      >
        <FaArrowDown />
      </Box>
    );
  }
};

export default RenderSortIcons;
