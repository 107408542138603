import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { useStyles } from "./styles";
import constants from ".././../constants/constants.json";
import PropTypes from "prop-types";

const Dropdown = ({ dataTestid, isNavbarOpen, ...props }) => {
  const classes = useStyles({ isNavbarOpen });
  return (
    <FormControl
      data-testid={dataTestid || "dropDownFormControl"}
      className={classes.form}
      size="small"
    >
      <InputLabel
        className={classes.label}
        id="label"
        disableAnimation={true}
        shrink={false}
      >
        {props.value || props.label}
      </InputLabel>
      <Select
        data-testid="dropDownSelect"
        sx={{
          backgroundColor: (theme) => theme.palette.bgColor,
          color: (theme) => theme.palette.bgColor,
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#E2E8F0",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E2E8F0",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E2E8F0",
          },
          ".MuiSvgIcon-root": {
            fill: (theme) => theme.palette.text.primary,
            fontSize: "1.6vw",
          },
          height: "2.2vw",
        }}
        labelId="select-outlined-label"
        id={props.Id}
        value={props?.value || ""}
        onClick={props.setDropdownValue}
      >
        {props.lists.map((item) => (
          <MenuItem
            data-testid="dropDownMenuItem"
            key={item.Id}
            value={item.Name}
          >
            {item.Name}
          </MenuItem>
        ))}
        {(props.value === constants.advanceFilters ||
          (props.label !== constants.sortBy &&
            props.value === constants.favorites)) && (
          <MenuItem key={"clear_filter"} value={"clearFilter"}>
            {constants.clearFilters}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
Dropdown.prototype = {
  dataTestid: PropTypes.string,
  isNavbarOpen: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  lists: PropTypes.array,
  Id: PropTypes.any,
};
export default Dropdown;
