import { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";
import { Stack } from "@mui/material";
import { useAppContext } from "context/appContext";
import constants from "../../constants/constants.json";
import Tickbox from "components/tickbox";

export default function AdvanceFilterModal({
  handleClose,
  isOpen,
  handlePageChange = () => {},
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const {
    filterOption,
    setFilterOption,
    setShowAdvanceFilterOptions,
    setSelectedFilters,
  } = useAppContext();
  const [selectedProductTypes, setSelectedProductTypes] = useState(
    filterOption.productType
  );
  const [selectedProductLinks, setSelectedProductLinks] = useState(
    filterOption.links
  );
  const { allproductType, allLinks } = filterOption;

  const handleProductTypeChange = (e) => {
    const { name, checked } = e.target;

    if (name && checked) {
      setSelectedProductTypes([
        ...new Set([...selectedProductTypes, name.split("_")[0]]),
      ]);

      setSelectedFilters([
        ...new Set([...selectedProductTypes, name.split("_")[0]]),
      ]);
    } else {
      setSelectedProductTypes([
        ...new Set([
          ...selectedProductTypes.filter((p) => p !== name.split("_")[0]),
        ]),
      ]);
      setSelectedFilters([
        ...new Set([
          ...selectedProductTypes.filter((p) => p !== name.split("_")[0]),
        ]),
      ]);
    }
  };

  useEffect(() => {
    if (filterOption?.clearFilters) {
      setSelectedProductLinks([]);
      setSelectedProductTypes([]);
      setSelectedFilters("");
    }
  }, [
    filterOption,
    setSelectedProductLinks,
    setSelectedProductTypes,
    setSelectedFilters,
  ]);

  const handleProductLinkChange = (e) => {
    const { name, checked } = e.target;

    if (name && checked) {
      setSelectedProductLinks([
        ...new Set([...selectedProductLinks, name.split("_")[0]]),
      ]);
      setSelectedFilters(
        selectedProductTypes +
          "," +
          [...new Set([...selectedProductLinks, name.split("_")[0]])]
      );
    } else {
      setSelectedProductLinks([
        ...new Set([
          ...selectedProductLinks.filter((l) => l !== name.split("_")[0]),
        ]),
      ]);
      setSelectedFilters(
        selectedProductTypes +
          "," +
          [
            ...new Set([
              ...selectedProductLinks.filter((p) => p !== name.split("_")[0]),
            ]),
          ]
      );
    }
  };

  const handleClearFilters = (e) => {
    if (
      selectedProductTypes.length !== 0 ||
      selectedProductLinks.length !== 0
    ) {
      setSelectedProductLinks([]);
      setSelectedProductTypes([]);
      setSelectedFilters("");
    }
    setFilterOption({
      ...filterOption,
      advanceFilter: false,
      filterByFavorites: false,
      productType: [],
      links: [],
      clearFilters: true,
      currentPage: 1,
    });

    setShowAdvanceFilterOptions(false);
  };

  const handleApplyFilter = () => {
    if (
      selectedProductTypes.length !== 0 ||
      selectedProductLinks.length !== 0
    ) {
      setFilterOption({
        ...filterOption,
        advanceFilter: true,
        filterByFavorites: false,
        productType: selectedProductTypes,
        links: selectedProductLinks,
        clearFilters: false,
        currentPage: 1,
      });
    }

    setShowAdvanceFilterOptions(false);
  };

  return (
    <div data-testid="AdvanceFilterModal">
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby={constants.advanceFilters}
        aria-describedby=""
      >
        <Box className={classes.wrapper} sx={{ boxShadow: 24 }}>
          <Box>
            <Typography variant="subtitle1" className={classes.text}>
              {constants.productTypes}
            </Typography>
            <Stack direction="row" justifyContent={"space-between"}>
              {allproductType?.length > 0 &&
                allproductType.map((productType) => (
                  <Fragment key={`${productType}}`}>
                    <Tickbox
                      dataTestId="productType"
                      id={`${productType}`}
                      name={productType}
                      isChecked={selectedProductTypes.includes(productType)}
                      setValue={handleProductTypeChange}
                    />
                  </Fragment>
                ))}
            </Stack>
          </Box>
          <Box>
            <Typography variant="subtitle1" className={classes.title}>
              {constants.ProductDrilldown}
            </Typography>
            <Stack direction="row" justifyContent={"space-between"}>
              {allLinks?.length > 0 &&
                allLinks.map((link) => (
                  <Fragment key={`${link}`}>
                    <Tickbox
                      dataTestId="links"
                      id={`${link}`}
                      name={link}
                      isChecked={selectedProductLinks.includes(link)}
                      setValue={handleProductLinkChange}
                    />
                  </Fragment>
                ))}
            </Stack>
          </Box>
          <Stack
            direction={"row"}
            flex
            justifyContent={"center"}
            sx={{ mt: 2 }}
          >
            <Button
              data-testid="AdvanceFilterCloseButton"
              onClick={() => setShowAdvanceFilterOptions(false)}
              className={classes.btn}
            >
              {constants.close}
            </Button>
            <Button
              data-testid="clearFilter"
              onClick={handleClearFilters}
              className={classes.btn}
            >
              {constants.clearFilters}
            </Button>
            <Button
              data-testid="applyFilter"
              onClick={handleApplyFilter}
              className={classes.btn}
            >
              {constants.apply}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
