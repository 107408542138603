import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  pageContainer: {
    marginTop: "125.5px",
    backgroundColor: ({ theme }) => theme.palette.themeColor,
  },
  tableText: {
    color: ({ theme }) => theme.palette.text.primary,
  },
  tab: {
    backgroundColor: "inherit",
    padding: "8px",
    left: ({ isNavbarOpen }) => (isNavbarOpen ? "20%" : "10%"),
    position: "static",
    right: "1%",

    paddingTop: 0,
  },
  tablebContainer: {
    backgroundImage: "none",
    marginBottom: "2rem",
    backgroundColor: ({ theme }) => `${theme.palette.bgColor} !important`,
    padding: 2,
    boxShadow: "0px 5px 14px 0px #00000033",
    borderRadius: "10px",
    maxHeight: 550,
    maxWidth: "100%",
  },
  text: {
    color: ({ theme }) => theme.palette.text.primary,
    paddingLeft: "8px",
    borderRight: "2px solid #A8ABC2",
    borderBottom: "2px solid #A8ABC2",
  },
  tableHead: {
    color: ({ theme }) => theme.palette.text.primary,
    background: "none",
    width: "5rem",
    borderRight: "2px solid #A8ABC2",
    borderTop: "2px solid #A8ABC2",
    borderBottom: `2px solid #A8ABC2`,
    marginLeft: 0,
    fontWeight: 500,
  },
  tableHead1: {
    background: "none",
    color: ({ theme }) => theme.palette.text.primary,
    borderRight: "2px solid #A8ABC2",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "12px",
    letterSpacing: "1px",
    textAlign: "left",
  },
  tableHeadTaka: {
    color: ({ theme }) => theme.palette.text.primary,
    textAlign: "center",
    borderBottom: "2px solid #A8ABC2",
    paddingRight: 8,
    paddingLeft: 8,
    fontSize: "12px",
    paddingTop: 8,
    background: "none",
    fontWeight: "700",
    lineHeight: "12px",
    letterSpacing: "1px",
    borderRight: "2px solid #A8ABC2",
    fontFamily: "Rubik",
    paddingBottom: 8,
  },

  takaFirstCol: {
    borderRight: "2px solid #A8ABC2 !important",
    borderBottom: "2px solid #A8ABC2",
    color: ({ theme }) => theme.palette.text.primary,
    fontWeight: 500,
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "17.78px",
    verticalAlign: "text-top",
    textAlign: "left",
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 8,
    paddingBottom: 8,
  },
  takaWaferOrder: {
    verticalAlign: "text-top",
    borderBottom: "2px solid #A8ABC2",
    borderRight: "2px solid #A8ABC2",
    background: ({ isDarkTheme }) => (isDarkTheme ? "none" : "#E7E9F2"),
    color: ({ isDarkTheme, theme }) =>
      isDarkTheme ? "#9584FF" : theme.palette.text.primary,
    fontWeight: 500,
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "17.78px",
    textAlign: "left",
    // maxWidth: "5px !important",
    minWidth: 95,
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 8,
    paddingBottom: 8,
  },
  takaBumpDie: {
    verticalAlign: "text-top",
    borderBottom: "2px solid #A8ABC2",
    background: ({ isDarkTheme }) => (isDarkTheme ? "none" : "#FFEAE7"),

    color: "#C7000C",
    fontWeight: 500,
    minWidth: 105,
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "17.78px",
    textAlign: "left",
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 8,
    paddingBottom: 8,
  },
  takaProb: {
    verticalAlign: "text-top",
    borderBottom: "2px solid #A8ABC2",
    background: ({ isDarkTheme }) => (isDarkTheme ? "none" : "#FEF1EC"),
    color: "#DD6B20",
    fontWeight: 500,
    minWidth: 100,
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "17.78px",
    textAlign: "left",
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 8,
    paddingBottom: 8,
  },
  takaAssembly: {
    borderBottom: "2px solid #A8ABC2",
    verticalAlign: "text-top",
    background: ({ isDarkTheme }) => (isDarkTheme ? "none" : "#FFFEEA"),
    color: "#E3A915",
    fontWeight: 500,
    minWidth: 100,
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "17.78px",
    textAlign: "left",
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 8,
    paddingBottom: 8,
  },
  takaFinalTest: {
    borderBottom: "2px solid #A8ABC2",
    verticalAlign: "text-top",
    background: ({ isDarkTheme }) => (isDarkTheme ? "none" : "#E9F9F5"),
    // color: "#18D642",
    color: "#1BC39B",
    fontWeight: 500,
    minWidth: 100,
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "17.78px",
    textAlign: "left",
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 8,
    paddingBottom: 8,
  },
  takaTransit: {
    borderBottom: "2px solid #A8ABC2",
    verticalAlign: "text-top",
    background: ({ isDarkTheme }) => (isDarkTheme ? "none" : "#E8FDFE"),
    color: "#008EAE",
    fontWeight: 500,
    minWidth: 80,
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "17.78px",
    textAlign: "left",
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 8,
    paddingBottom: 8,
  },
  takaAdvantest: {
    background: ({ isDarkTheme }) => (isDarkTheme ? "none" : "#F5E6ED"),
    color: ({ isDarkTheme }) => (isDarkTheme ? "#FF006B" : "#9A0143"),
    verticalAlign: "text-top",
    fontWeight: 500,
    minWidth: 80,
    fontFamily: "Rubik",
    fontSize: "12px",
    lineHeight: "17.78px",
    textAlign: "left",
    borderBottom: "2px solid #A8ABC2",
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 8,
    paddingBottom: 8,
  },
  tootip: {
    width: "100%",
    height: "100%",
  },

  loadingWrapper: {
    marginRight: "10%",
    height: "55vh",
  },
});

const commonStyle = {
  backgroundColor: "#ffff",
  borderRadius: "8px",

  border: "1px solid transparent",
  backgroundImage:
    "linear-gradient(white, white), linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12))",
  backgroundOrigin: "border-box",
  padding: 1.5,
  backgroundClip: "content-box, border-box",
};
export const imageStyle = createUseStyles({
  imageContainer: {
    background: "#ffff",
    position: "absolute",
    bottom: "84.5%",
    left: "22%",
    top: "75%",
  },
  image: {
    ...commonStyle,
    opacity: ({ isImageLoaded }) => (isImageLoaded ? 1 : 0),
    width: "100px",
    height: "100px",
    objectFit: "contain",
  },
  imageSkeleton: {
    width: "100%",
    ...commonStyle,
  },
});
