export async function getOverviewLevel3({ React, productId, template = null }) {
  const options = {
    op_code: "get_level3_page_v01",
    product_id: Number(productId),
    function_id: 2,
    offset: 0,
    count: 100,
    ...(template ? { template } : ""),
  };

  const res = await React.$post(React.$apis.overviewLevel3, options);
  return res.data;
}
