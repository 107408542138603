import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  wrapper: {
    position: "absolute",
    top: "50%",
    left: ({ isNavbarOpen }) => isNavbarOpen ? "58%" : "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    backgroundColor: ({ theme }) => theme.palette.bgColor,
    borderRadius: "8px",
  },
  textWrapper: {
    textAlign: "center",
  },
  text: {
    color: ({ theme }) => theme.palette.text.primary,
  },
});
