import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  pageWrapper: {
    width: "100%",
    height: "80vh",
    backgroundColor: ({ theme }) => theme.palette.bgColor,
    boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.2)",
    "@media (max-height: 700px)": {
      height: "75vh",
    },
  },

  detailbtn: {
    backgroundColor: ({ theme }) => theme.palette.button.primary,
    fontSize: "11px",
    color: ({ theme }) => theme.palette.text.primary,
    border: "2px solid #A8ABC2",
    fontWeight: 700,
    fontFamily: "Rubik",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  compliancystatus: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  statusYes: {
    color: "green",
    marginRight: "8px",
  },

  statusNo: {
    color: "rgb(191,38,38)",
    marginRight: "8px",
  },

  unknownStatus: {
    color: "rgb(170,170,10)",
    marginRight: "8px",
  },

  statusCode: {
    width: "15px",
    height: "15px",
    borderRadius: "3px",
    marginRight: "10px",
  },

  statusText: {
    width: "40px",
    height: "15px",
    color: ({ theme }) => theme.palette.text.primary,
    textAlign: "left",
    marginTop: "4px",
  },

  titleWrapper: {
    width: "100%",
    height: "40px",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",

    "@media (max-width: 1140px)": {
      flexDirection: "column",
      marginBottom: "25px",
      gap: "15px",
      alignItems: "flex-start",
      height: "80px",
    },
  },

  title: {
    fontSize: "20px",
    color: ({ theme }) => theme.palette.text.primary,
    fontWeight: "500",
    marginTop: "2rem",
  },

  dragBtn: {
    textTransform: "none",
    width: "240px",
    height: "100%",
    border: "none",
    background: "none",
    cursor: "pointer",
    fontSize: "13px",
    color: ({ theme }) => theme.palette.text.primary,
  },

  dragBtngroup: {
    width: "432px",
    height: "38px",
    border: "2px solid #A8ABC2",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "5px",
    backgroundColor: ({ theme }) => theme.palette.bgColor,

    "& > div:nth-child(2)": {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: "500",
      color: "red",
    },
  },

  dragBtngroupContainer: {
    display: "flex",
    alignItems: "center",
    gap: "25px",
  },

  searchInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "5px",
    height: "38px",
    width: "250px",
    border: "2px solid #A8ABC2",
    borderRadius: "5px",
    backgroundColor: ({ theme }) => theme.palette.bgColor,

    "& > input": {
      width: "100%",
      height: "100%",
      paddingInline: "10px",
      border: "none",
      borderRadius: "5px",
      color: ({ theme }) => theme.palette.text.primary,
      backgroundColor: ({ theme }) => theme.palette.bgColor,
    },

    "& > input:focus": {
      outline: "none",
    },

    "& > div": {
      width: "35px",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "18px",
      cursor: "pointer",
      color: ({ theme }) => theme.palette.text.primary,
      backgroundColor: ({ theme }) => theme.palette.bgColor,
    },

    "& > div:hover": {
      backgroundColor: "rgba(0,0,0,0.4)",
      borderRadius: "50%",
      padding: "0.2vw",
    },
  },

  closeIcon: {
    "&:hover": {
      padding: "0.2vw",
      backgroundColor: "rgba(0,0,0,0.4)",
      borderRadius: "50%",
    },
  },

  uploadBtn: {
    background: "#9A0143",
    height: "100%",
    width: "160px",
    borderRadius: "5px",
    fontWeight: "700",
    color: "#fff",
    fontSize: "12px",
    cursor: "pointer",
    border: "none",
    fontFamily: "Rubik",
    "&:hover": {
      backgroundColor: "#9A0143",
    },
  },

  computationdate: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: ({ theme }) => theme.palette.text.primary,
  },
  tableWrapper: {
    width: "100%",
    marginBottom: "2rem",
    height: '60vh'
  },
});
