import { getTimeStamp } from "utils/tools";
const headers = [
    "LotNumber",
    "DieReceipt",
    "ReceiptBumpDuration",
    "BumpIn",
    "BumpDuration",
    "BumpOut",
    "BumpProbeDuration",
    "ProbeIn",
    "ProbeDuration",
    "ProbeOut",
    "ProbeAssemblyDuration",
    "AssemblyIn",
    "AssemblyDuration",
    "AssemblyOut",
    "AssemblyTestDuration",
    "TestIn",
    "TestDuration",
    "TestOut",
    "TestShipDuration",
    "ShipOut",
    "BumpYield",
    "BumpOutDie",
    "ProbeYield",
    "ProbeOutDie",
    "AssemblyYield",
    "AssemblyOutDie",
    "TestYield",
    "TestOutDie",
    "TestOutDieM",
    "TestOutDieN",
    "TakaDRatio",
];
export const downloadAllEntries = (data) => {

    // Convert data to CSV format
    const csvContent =
        "data:text/csv;charset=utf-8," +
        headers.join(",") +
        "\n" +
        data
            .map((row) => headers.map((header) => row[header]).join(","))
            .join("\n");

    // Create a virtual link element to trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `LotStatusTrackingAll_${getTimeStamp()}.csv`);
    document.body.appendChild(link);

    // Trigger the download
    link.click();
};

export const downloadFilteredEntries = (data) => {
    // Convert data to CSV format
    const csvContent =
        "data:text/csv;charset=utf-8," +
        headers.join(",") +
        "\n" +
        data
            .map((row) => headers.map((header) => row[header]).join(","))
            .join("\n");

    // Create a virtual link element to trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `LotStatusTracking_${getTimeStamp()}.csv`);
    document.body.appendChild(link);

    // Trigger the download
    link.click();
};