import { Paper, InputBase, IconButton, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useAppContext } from "../../context/appContext";
import { useStyles } from "./styles";

const Search = ({
  dataTestid,
  value,
  cssClass = null,
  handleChange = null,
  width = "10.1vw",
  height,
  minWidth = "10.1vw",
  ...props
}) => {
  const { searchQuery, setSearchQuery } = useAppContext();
  const theme = useTheme();
  const classes = useStyles({ theme, width, height, minWidth });
  const isDarkTheme = useTheme().palette.mode === "dark";
  const searchInputValue = value ?? searchQuery;
  return (
    <Paper
      data-testid={dataTestid ?? "search"}
      component="form"
      className={cssClass ?? classes.wrapper}
    >
      <InputBase
        data-testid="searchIp"
        variant="outlined"
        className={classes.input}
        placeholder={props.label}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
          }
        }}
        onChange={handleChange ?? setSearchQuery}
        value={searchInputValue || ""}
        sx={{
          input: {
            backgroundColor: `${theme.palette.bgColor} !important`,
            textOverflow: "ellipsis",
            overflow: "hidden !important",
            whiteSpace: "nowrap",
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            "&::placeholder": {
              color: theme.palette.text.primary,
              fontSize: "0.7vw",
              opacity: isDarkTheme ? 0.3 : 0.8,
            },
          },
        }}
      />
      <IconButton type="button" className={classes.icon} aria-label="search">
        <SearchIcon
          fontSize="small"
          sx={{
            fontSize: "1.3rem",
            "@media (min-width: 800px) and (max-width: 1500px)": {
              fontSize: "14px",
            },
            "@media (min-width: 600px) and (max-width: 799px)": {
              fontSize: "10px",
            },
          }}
        />
      </IconButton>
    </Paper>
  );
};

export default Search;
