import Fade from "@mui/material/Fade";
import { useState, useEffect, useCallback } from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropsTypes from "prop-types";

const FadeOnScroll = ({ children }) => {
  const [isAtTop, setIsAtTop] = useState(true);

  const target = document.getElementById("root");
  const hideproductImage = useScrollTrigger({
    target,
    threshold: 10,
  });

  const handleScroll = useCallback(() => {
    if (target) {
      setIsAtTop(target.scrollTop === 0);
    }
  }, [target]);

  useEffect(() => {
    const element = target;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, hideproductImage, target]);

  return <Fade in={!hideproductImage && isAtTop}>{children}</Fade>;
};

FadeOnScroll.propTypes = {
  children: PropsTypes.any,
};

export default FadeOnScroll;
