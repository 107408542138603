import Box from "@mui/material/Box";
import { useStyles } from "./style";
import { useTheme } from "@emotion/react";
import SupplayAndDemandPageTabs from "components/supplyAndDemand/Tabs/supplyAndDemandTabs";
export default function SupplyAndDemand({ isNavbarOpen }) {
  const theme = useTheme();
  const classes = useStyles({
    isNavbarOpen,
    theme,
  });

  return (
    <Box className={classes.containerBox}>
      <Box
        className={classes.pageContainer}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <div className={classes.pageWrapper}>
          <SupplayAndDemandPageTabs isNavbarOpen={isNavbarOpen} />
        </div>
      </Box>
    </Box>
  );
}
