import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useStyles } from "./styles";
import { useCallback, useEffect } from "react";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import constants from "../../constants/constants.json";

export default function CustomBreadcrumbs({
  product = [],
  fid = 0,
  tid = undefined,
}) {
  const routeMaps = {
    "/profile": "Profile",
    "/unauthorized": "Access Denied",
    "/error": "Internal Server Error",
    "/notfound": "Not Found",
    "/product": product?.product_name,
  };
  const navigate = useNavigate();
  const tab = {
    1: "Supply & Demand",
    2: "Parametric Analysis",
    5: "Helvetia Compliancy Check",
  };
  const classes = useStyles();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const names = location.pathname
    .split("/")
    .filter((x) => x)
    .map((x) => "/" + x);

  const isCustom = names[0] === "/overview";

  const getDefaultTabRoute = useCallback(() => {
    if (isCustom && product?.link_name?.some((p) => p.function_id === 1))
      return 1;
    else if (product?.link_name) return product?.link_name[0]?.function_id;
    else return 2;
  }, [isCustom, product?.link_name]);

  useEffect(() => {
    if (isCustom && fid === 0 && product?.product_id !== undefined) {
      navigate(
        `/overview/entry/${product?.product_id}/${getDefaultTabRoute()}`
      );
    }
  }, [
    fid,
    navigate,
    product?.product_id,
    isCustom,
    product,
    product?.product_id,
    getDefaultTabRoute,
  ]);

  const url =
    tid === undefined ? `/product/${tab[fid]}` : `/product/${tab[fid]}/${tid}`;

  const customItems = url
    .split("/")
    .filter((x) => x)
    .map((x) => "/" + x);

  const pathnames = isCustom ? customItems : names;
  const isHomePage =
    location.pathname
      .split("/")
      .filter((x) => x)
      .map((x) => "/" + x).length === 0;

  const renderBreadcrumb = () => {
    if (isCustom) {
      return pathnames.map((name, index) => {
        const isFunction = name.slice(1) === tab[fid];
        const isTid = name.slice(1) === tid;
        const tidName = decodeURIComponent(
          searchParams.get("template")?.split("/")?.pop()
        );

        if (isFunction) {
          return (
            <Link
              className={classes.link}
              style={{ cursor: tid === undefined ? "text" : "pointer" }}
              to={`/overview/entry/${product?.product_id}/${Number(fid)}`}
              key={name}
            >
              {tab[fid]}
            </Link>
          );
        } else if (isTid) {
          return (
            <Typography className={classes.text} key={name}>
              {`Template ${tidName}`}
            </Typography>
          );
        } else {
          return (
            <Link
              className={classes.link}
              to={`/overview/entry/${
                product?.product_id
              }/${getDefaultTabRoute()}`}
              key={name}
            >
              {routeMaps[name]}
            </Link>
          );
        }
      });
    } else {
      return pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography className={classes.text} key={name}>
            {routeMaps[name]}
          </Typography>
        ) : (
          <Link className={classes.link} to={routeTo} key={name}>
            {routeMaps[name]}
          </Link>
        );
      });
    }
  };

  return (
    <Breadcrumbs
      data-testid="custom-breadcrumbs"
      classes={{ separator: classes.separator }}
      separator={
        <NavigateNextIcon className={classes.nextIcon} fontSize="small" />
      }
      aria-label="breadcrumb"
    >
      <Link
        to="/"
        className={classes.link}
        style={{ cursor: isHomePage ? "text" : "pointer" }}
      >
        <AutoAwesomeMotionIcon
          fontSize="small"
          className={classes.productsIcon}
        />
        <span className={classes.productsText}>{constants.productsText}</span>
      </Link>
      {renderBreadcrumb()}
    </Breadcrumbs>
  );
}
