import { Fragment } from "react";
import { Box, useTheme } from "@mui/material";
import { useStyles } from "./styles";
import Tickbox from "components/tickbox";

const ShowHidePopup = ({ showHideList, setShowHideList }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const values = [
    { id: "dates", name: "Dates" },
    { id: "duration", name: "Duration" },
    { id: "yield", name: "Yield / Quantity" },
  ];

  const updateCheckBoxes = (evt, item) => {
    if (item.id === "duration" && evt.target.checked) {
      setShowHideList({
        ...showHideList,
        [item.id]: evt.target.checked,
        dates: true,
      });
      return;
    } else if (item.id === "dates" && !evt.target.checked) {
      setShowHideList({
        ...showHideList,
        [item.id]: evt.target.checked,
        duration: false,
      });
      return;
    }
    setShowHideList({
      ...showHideList,
      [item.id]: evt.target.checked,
    });
  };

  return (
    <Box
      data-testid={"show-hide"}
      className={classes.wrapper}
      onClick={(e) => e.stopPropagation()}
    >
      {values.map((item) => {
        return (
          <Fragment key={item.id}>
            <Tickbox
              id={item.id}
              name={item.name}
              isChecked={showHideList[item.id]}
              setValue={(evt) => {
                updateCheckBoxes(evt, item);
              }}
            />
          </Fragment>
        );
      })}
    </Box>
  );
};

export default ShowHidePopup;
