import constants from "../../constants/constants.json";

export function sortCards({ data, sortOption }) {
  if (!data?.length) return [];
  switch (sortOption) {
    case constants.sortDesc:
      return data.sort((a, b) => b.product_name.localeCompare(a.product_name));
    case constants.favorites:
      return data;
    default:
      return data.sort((a, b) => a.product_name.localeCompare(b.product_name));
  }
}

export async function getOverviewLevel1({ React }) {
  return await React.$post(React.$apis.overviewLevel1, {
    op_code: "get_level1_page_v01",
    time_start: "2021-01-01 00:00:00",
    time_end: "2021-03-01 00:00:00",
    offset: 0,
    count: 9,
  });
}
