import { createUseStyles } from 'react-jss';
export const useStyles =
    createUseStyles(({
        appBar: {
            backgroundColor: ({ theme }) => `${theme.palette.footer?.color} !important`,
            top: "100%",
            bottom: 0,
        },
        footerWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            width: '33%',
            alignItems: 'center',
            height: '4vh'
        },
        link: {
            textDecoration: 'none'
        },
        btn: {
            marginRight: '10px'
        },
        btnWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '55%',
            width: '65%'
        },
        copyright: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: '15%',
        },
        copyrightText: {
            fontSize: '0.75vw',
            color: ({ theme }) => theme.palette.text.primary,
        },
        icon: {
            justifyContent: 'space-between',
            display: 'flex',
            width: '6%'
        },
        img: {
            width: "9vw",
            objectFit: 'contain'
        },
        text: {
            color: ({ theme }) => theme.palette.text.primary,
            fontSize: '0.75vw'
        },
        xicon: {
            fontSize: "1.1vw",
            marginTop: '3px'
        },
        fbIcon: {
            fontSize: "1.4vw"
        },
        linkWrapper: {
            flexDirection: 'row',
            display: 'flex',
            width: '50%',
            justifyContent: 'space-between',
            marginLeft: '-3%',
            marginTop: '1%'
        },
        imgWrapper: {
            display: 'flex',
            justifyContent: 'center'
        }
    }))