import React from "react";
import ReactDom from "react-dom";

import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { myAdvaLogin } from "./config/secrets";
import "./assets/style/minireset.css";
import "./assets/style/base.scss";
import "./assets/style/layout.scss";
import App from "./App";
import "./middleware/reference";

ReactDom.render(
  <Auth0Provider
    domain={myAdvaLogin.domain}
    clientId={myAdvaLogin.clientId}
    audience={myAdvaLogin.audience}
    redirectUri={window.location.origin}
    cacheLocation="memory"
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
