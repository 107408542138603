import React, { useCallback, useEffect, useState } from "react";
import { useStyles } from "./style";
import constants from "../../../constants/constants.json";
import clsx from "clsx";
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Search from "components/search";
import MultiCalendar from "components/multiCalendar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs from "dayjs";
import { MdFilterAltOff } from "react-icons/md";
import { RiDownload2Fill } from "react-icons/ri";
import ShowHidePopup from "components/supplyAndDemand/showHidePopup";
import { downloadAllEntries, downloadFilteredEntries } from "./utils";
import Table from "../../../components/supplyAndDemand/lotsStatusTracking/Table/Table";
import Plot from "components/supplyAndDemand/plot";

const LotsStatusTracking = ({
  stageFilter,
  setStageFilter,
  lotStatusTableRef,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [filterBy, setFilterBy] = useState("");
  const [searchChange, setSearchChange] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [filteredData, setFilteredData] = useState({});
  const [currentRecords, setCurrentRecords] = useState({});
  const [lotsStatusData, setLotsStatusData] = useState({});
  const [selectedStages, setSelectedStages] = useState({});

  const [showHideList, setShowHideList] = useState({
    dates: true,
    duration: true,
    yield: true,
  });

  const getAllTimeDateRange = () => {
    const startDate = dayjs("2019-01-01");
    const endDate = dayjs();
    const allTimeRange = [startDate, endDate.endOf("day")];
    const formattedRange = allTimeRange.map((date) =>
      date.format("YYYY-MM-DD")
    );
    return formattedRange;
  };

  useEffect(() => {
    const formattedRange = getAllTimeDateRange();
    setSelectedDates(formattedRange);
  }, []);

  const getSelectedDateRangeFromCalendar = useCallback((dateRange) => {
    setSelectedDates(dateRange);
  }, []);

  const updateClearFilters = useCallback(() => {
    setClearFilters(false);
    const formattedRange = getAllTimeDateRange();
    setSelectedDates(formattedRange);
    setFilterBy("");
  }, []);

  return (
    <Box className={classes.pageContainer} ref={lotStatusTableRef}>
      <Grid container className={classes.headerWrapper}>
        <Typography className={classes.title}>
          {constants.lotsStatusTracking}
        </Typography>
        <Grid item xs={6} className={classes.headerGroupWrapper}>
          <Search
            height="3.5vh"
            width="9.4vw"
            minWidth="140px"
            dataTestid="search"
            label={constants.searchLotNoLabel}
            handleChange={(e) => {
              setFilterBy(e.target.value);
              setSearchChange(true);
            }}
            value={filterBy}
          />
          <Box
            className={classes.input}
            data-testid="select-date-box"
            onClick={() => setShowCalendar((prev) => !prev)}
          >
            <ClickAwayListener onClickAway={() => setShowCalendar(false)}>
              <Button
                className={clsx(classes.btn, classes.dateBtn)}
                data-testid="selected-date"
              >
                {selectedDates && (
                  <p>
                    {selectedDates.map((date, index) => (
                      <React.Fragment key={index}>
                        {index !== 0 && " - "}
                        {date}
                      </React.Fragment>
                    ))}
                  </p>
                )}
                <ArrowDropDownIcon className={classes.arrowIcon} />
              </Button>
            </ClickAwayListener>
          </Box>
          <Box
            className={classes.input}
            data-testid="select-columns-box"
            onClick={() => setShowColumns((prev) => !prev)}
          >
            <ClickAwayListener onClickAway={() => setShowColumns(false)}>
              <Button
                data-testid="show-hide-columns"
                className={clsx(classes.btn, classes.showHide)}
              >
                {constants.showHideColumns}
                <ArrowDropDownIcon className={classes.arrowIcon} />
              </Button>
            </ClickAwayListener>
          </Box>
        </Grid>
        <Grid item xs={1} className={classes.btnWrapper}>
          <Tooltip title={constants.clearFilters}>
            <IconButton
              sx={{ ".MuiButton-root": { width: "7px" } }}
              size="small"
              data-testid="clear-filter"
              className={classes.icons}
              onClick={() => setClearFilters(true)}
            >
              <MdFilterAltOff size={17} />
            </IconButton>
          </Tooltip>

          <Tooltip title={constants.downloadCurrent}>
            <IconButton
              size="small"
              data-testid="download-current-entries"
              className={classes.icons}
              onClick={() => downloadFilteredEntries(currentRecords)}
            >
              <RiDownload2Fill size={17} />
            </IconButton>
          </Tooltip>

          <Tooltip title={constants.downloadAll}>
            <IconButton
              size="small"
              data-testid="download-all-entries"
              onClick={() => downloadAllEntries(lotsStatusData)}
              className={classes.icons}
            >
              <RiDownload2Fill size={17} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Box
        className={clsx(
          classes.calendarAnchor,
          showCalendar ? classes.active : classes.inactive
        )}
      >
        <MultiCalendar
          onDateSelect={(value) => getSelectedDateRangeFromCalendar(value)}
          setShowCalendar={setShowCalendar}
          selectedDates={selectedDates}
          selectedCheckboxes={(value) => setSelectedStages(value)}
          clearFilters={clearFilters}
        />
      </Box>
      <Box
        className={clsx(
          classes.columnsAnchor,
          showColumns ? classes.active : classes.inactive
        )}
      >
        <ShowHidePopup
          setShowHideList={setShowHideList}
          showHideList={showHideList}
        />
      </Box>

      <Table
        selectedDates={selectedDates}
        filterBy={filterBy}
        showHideList={showHideList}
        setCurrentFilteredData={(data) => {
          setFilteredData(data);
        }}
        setAllRecords={(data) => {
          setCurrentRecords(data);
        }}
        setAllData={(data) => {
          setLotsStatusData(data);
        }}
        searchChange={searchChange}
        setSearchChange={setSearchChange}
        selectedStages={selectedStages}
        clearFilters={clearFilters}
        setClearFilters={updateClearFilters}
        stageFilter={stageFilter}
      />

      {filteredData?.length ? <Plot data={filteredData} /> : null}
    </Box>
  );
};

export default LotsStatusTracking;
